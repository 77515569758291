export const MOBILE_NUMBER_SET = 'MOBILE_NUMBER_SET';
export const LOCATION_VALUE_SET = 'LOCATION_VALUE_SET';
export const MOBILE_OTP_SET = 'MOBILE_OTP_SET';
export const PHONE_LAST_DIGIT_SET = 'PHONE_LAST_DIGIT_SET';

export const VERIFIED_ACCOUNT_DIALOG_SHOW = 'VERIFIED_ACCOUNT_DIALOG_SHOW';
export const VERIFIED_ACCOUNT_DIALOG_HIDE = 'VERIFIED_ACCOUNT_DIALOG_HIDE';
export const WON_BADGE_DIALOG_SHOW = 'WON_BADGE_DIALOG_SHOW';
export const WON_BADGE_DIALOG_HIDE = 'WON_BADGE_DIALOG_HIDE';

export const SELECT_LANGUAGE_DIALOG_SHOW = 'SELECT_LANGUAGE_DIALOG_SHOW';
export const SELECT_LANGUAGE_DIALOG_HIDE = 'SELECT_LANGUAGE_DIALOG_HIDE';

export const REFER_DIALOG_SHOW = 'REFER_DIALOG_SHOW';
export const REFER_DIALOG_HIDE = 'REFER_DIALOG_HIDE';

export const FETCH_PROFILE_BADGES_SUCCESS = 'FETCH_PROFILE_BADGES_SUCCESS';
export const FETCH_PROFILE_BADGES_IN_PROGRESS = 'FETCH_PROFILE_BADGES_IN_PROGRESS';
export const FETCH_PROFILE_BADGES_ERROR = 'FETCH_PROFILE_BADGES_ERROR';

export const FETCH_PROFILE_STREAK_SUCCESS = 'FETCH_PROFILE_STREAK_SUCCESS';
export const FETCH_PROFILE_STREAK_IN_PROGRESS = 'FETCH_PROFILE_STREAK_IN_PROGRESS';
export const FETCH_PROFILE_STREAK_ERROR = 'FETCH_PROFILE_STREAK_ERROR';

export const FETCH_PROFILE_IMAGE_SUCCESS = 'FETCH_PROFILE_IMAGE_SUCCESS';
export const FETCH_PROFILE_IMAGE_IN_PROGRESS = 'FETCH_PROFILE_IMAGE_IN_PROGRESS';
export const FETCH_PROFILE_IMAGE_ERROR = 'FETCH_PROFILE_IMAGE_ERROR';

export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_PROFILE_IMAGE_IN_PROGRESS = 'UPLOAD_PROFILE_IMAGE_IN_PROGRESS';
export const UPLOAD_PROFILE_IMAGE_ERROR = 'UPLOAD_PROFILE_IMAGE_ERROR';

export const USER_NAME_SET = 'USER_NAME_SET';

export const UPLOAD_USER_DETAILS_SUCCESS = 'UPLOAD_USER_DETAILS_SUCCESS';
export const UPLOAD_USER_DETAILS_ERROR = 'UPLOAD_USER_DETAILS_ERROR';
export const UPLOAD_USER_DETAILS_IN_PROGRESS = 'UPLOAD_USER_DETAILS_IN_PROGRESS';
