import {
    FETCH_PROFILE_BADGES_ERROR,
    FETCH_PROFILE_BADGES_IN_PROGRESS,
    FETCH_PROFILE_BADGES_SUCCESS,
    FETCH_PROFILE_IMAGE_ERROR,
    FETCH_PROFILE_IMAGE_IN_PROGRESS,
    FETCH_PROFILE_IMAGE_SUCCESS,
    FETCH_PROFILE_STREAK_ERROR,
    FETCH_PROFILE_STREAK_IN_PROGRESS,
    FETCH_PROFILE_STREAK_SUCCESS,
    LOCATION_VALUE_SET,
    MOBILE_NUMBER_SET,
    MOBILE_OTP_SET,
    PHONE_LAST_DIGIT_SET,
    REFER_DIALOG_HIDE,
    REFER_DIALOG_SHOW,
    SELECT_LANGUAGE_DIALOG_HIDE,
    SELECT_LANGUAGE_DIALOG_SHOW,
    VERIFIED_ACCOUNT_DIALOG_HIDE,
    VERIFIED_ACCOUNT_DIALOG_SHOW,
    WON_BADGE_DIALOG_HIDE,
    WON_BADGE_DIALOG_SHOW,
    UPLOAD_PROFILE_IMAGE_IN_PROGRESS,
    UPLOAD_PROFILE_IMAGE_ERROR,
    UPLOAD_PROFILE_IMAGE_SUCCESS,
    USER_NAME_SET,
    UPLOAD_USER_DETAILS_SUCCESS,
    UPLOAD_USER_DETAILS_IN_PROGRESS,
    UPLOAD_USER_DETAILS_ERROR,
} from '../constants/profile';
import { PROFILE_IMAGE_URL, PROFILE_DETAILS_URL, urlFetchProfileBadges, urlFetchProfileStreak } from '../constants/url';
import Axios from 'axios';

export const setMobileNumber = (value, validation) => {
    return {
        type: MOBILE_NUMBER_SET,
        value,
        validation,
    };
};

export const setLocationValue = (value) => {
    return {
        type: LOCATION_VALUE_SET,
        value,
    };
};

export const setMobileOTP = (value) => {
    return {
        type: MOBILE_OTP_SET,
        value,
    };
};

export const showVerifiedAccountDialog = (value) => {
    return {
        type: VERIFIED_ACCOUNT_DIALOG_SHOW,
        value,
    };
};

export const hideVerifiedAccountDialog = () => {
    return {
        type: VERIFIED_ACCOUNT_DIALOG_HIDE,
    };
};

export const showWonBadgeDialog = (value) => {
    return {
        type: WON_BADGE_DIALOG_SHOW,
        value,
    };
};

export const hideWonBadgeDialog = () => {
    return {
        type: WON_BADGE_DIALOG_HIDE,
    };
};

export const showSelectLanguageDialog = (value) => {
    return {
        type: SELECT_LANGUAGE_DIALOG_SHOW,
        value,
    };
};

export const hideSelectLanguageDialog = () => {
    return {
        type: SELECT_LANGUAGE_DIALOG_HIDE,
    };
};

export const showReferDialog = (value) => {
    return {
        type: REFER_DIALOG_SHOW,
        value,
    };
};

export const hideReferDialog = () => {
    return {
        type: REFER_DIALOG_HIDE,
    };
};

export const setPhoneLastDigit = (value) => {
    return {
        type: PHONE_LAST_DIGIT_SET,
        value,
    };
};

const fetchProfileBadgesInProgress = () => {
    return {
        type: FETCH_PROFILE_BADGES_IN_PROGRESS,
    };
};

const fetchProfileBadgesSuccess = (value, skip, limit, total) => {
    return {
        type: FETCH_PROFILE_BADGES_SUCCESS,
        value,
        skip,
        limit,
        total,
    };
};

const fetchProfileBadgesError = (message) => {
    return {
        type: FETCH_PROFILE_BADGES_ERROR,
        message,
    };
};

export const fetchProfileBadges = (skip, limit, cb) => (dispatch) => {
    dispatch(fetchProfileBadgesInProgress());

    const url = urlFetchProfileBadges(skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_agni_sakshi'),
        },
    })
        .then((res) => {
            dispatch(fetchProfileBadgesSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res.data && res.data.result && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result && res.data.result.list, res && res.data && res.data.result && res.data.result.count);
            }
        })
        .catch((error) => {
            dispatch(fetchProfileBadgesError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchProfileImageInProgress = () => {
    return {
        type: FETCH_PROFILE_IMAGE_IN_PROGRESS,
    };
};

const fetchProfileImageSuccess = (value) => {
    return {
        type: FETCH_PROFILE_IMAGE_SUCCESS,
        value,
    };
};

const fetchProfileImageError = (message) => {
    return {
        type: FETCH_PROFILE_IMAGE_ERROR,
        message,
    };
};

export const fetchProfileImage = () => (dispatch) => {
    dispatch(fetchProfileImageInProgress());

    Axios.get(PROFILE_IMAGE_URL, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_agni_sakshi'),
        },
    })
        .then((res) => {
            const profileImage = res.data;
            const file = new Blob([profileImage], { type: profileImage.type });
            const fileUrl = URL.createObjectURL(file);

            dispatch(fetchProfileImageSuccess(fileUrl));
        })
        .catch((error) => {
            dispatch(fetchProfileImageError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchProfileStreakInProgress = () => {
    return {
        type: FETCH_PROFILE_STREAK_IN_PROGRESS,
    };
};

const fetchProfileStreakSuccess = (value) => {
    return {
        type: FETCH_PROFILE_STREAK_SUCCESS,
        value,
    };
};

const fetchProfileStreakError = (message) => {
    return {
        type: FETCH_PROFILE_STREAK_ERROR,
        message,
    };
};

export const fetchProfileStreak = (cb) => (dispatch) => {
    dispatch(fetchProfileStreakInProgress());

    const url = urlFetchProfileStreak();
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_agni_sakshi'),
        },
    })
        .then((res) => {
            dispatch(fetchProfileStreakSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchProfileStreakError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const uploadProfileImageInProgress = () => {
    return {
        type: UPLOAD_PROFILE_IMAGE_IN_PROGRESS,
    };
};

const uploadProfileImageSuccess = (value) => {
    return {
        type: UPLOAD_PROFILE_IMAGE_SUCCESS,
        value,
    };
};

const uploadProfileImageError = (message) => {
    return {
        type: UPLOAD_PROFILE_IMAGE_ERROR,
        message,
    };
};

export const uploadProfileImage = (file, cb) => (dispatch) => {
    dispatch(uploadProfileImageInProgress());

    const formData = new FormData();
    formData.append('file', file);

    Axios.put(PROFILE_IMAGE_URL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_agni_sakshi'),
        },
    })
        .then((res) => {
            const { data } = res;
            dispatch(uploadProfileImageSuccess(data));
            if (cb) {
                cb(data);
            }
        })
        .catch((error) => {
            dispatch(uploadProfileImageError(
                error.response && error.response.data && error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

export const setProfileUserName = (value) => {
    return {
        type: USER_NAME_SET,
        value,
    };
};

const uploadUserDetailsInProgress = () => {
    return {
        type: UPLOAD_USER_DETAILS_IN_PROGRESS,
    };
};

const uploadUserDetailsSuccess = (value) => {
    return {
        type: UPLOAD_USER_DETAILS_SUCCESS,
        value,
    };
};

const uploadUserDetailsError = (message) => {
    return {
        type: UPLOAD_USER_DETAILS_ERROR,
        message,
    };
};

export const uploadUserDetails = (data, cb) => (dispatch) => {
    dispatch(uploadUserDetailsInProgress());

    Axios.put(PROFILE_DETAILS_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_agni_sakshi'),
        },
    })
        .then((res) => {
            dispatch(uploadUserDetailsSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(uploadUserDetailsError(error.response && error.response.data &&
                error.response.data.error && error.response.data.error.details &&
                error.response.data.error.details.length && error.response.data.error.details[0].message
                ? error.response.data.error.details[0].message
                : error.response && error.response.data && error.response.data.message
                    ? error.response && error.response.data && error.response.data.message
                    : 'Failed'));

            if (cb) {
                cb(null);
            }
        });
};
