import React, { lazy, Suspense, useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import CircularProgress from './components/CircularProgress';
import NavBar from './containers/NavBar';
import TopNavBar from './containers/TopNavBar';

const Home = lazy(() => import('./containers/Home').then().catch());
const InteractiveVideo = lazy(() => import('./containers/InteractiveVideo').then().catch());
const Wallet = lazy(() => import('./containers/Wallet').then().catch());
const UserProfile = lazy(() => import('./containers/UserProfile').then().catch());
const Leaderboard = lazy(() => import('./containers/Leaderboard').then().catch());
const Cast = lazy(() => import('./containers/Cast').then().catch());
const MobileNumber = lazy(() => import('./containers/TopNavBar/MobileNumber').then().catch());
const MobileOTP = lazy(() => import('./containers/TopNavBar/MobileOTP').then().catch());
const Badges = lazy(() => import('./containers/UserProfile/Badges').then().catch());
const TermsConditions = lazy(() => import('./containers/UserProfile/TermsConditions').then().catch());
const Profile = lazy(() => import('./containers/UserProfile/Profile').then().catch());
const Updates = lazy(() => import('./containers/UserProfile/Updates').then().catch());
const AboutContest = lazy(() => import('./containers/AboutContest').then().catch());
const AccountDetails = lazy(() => import('./containers/UserProfile/AccountDetails').then().catch());
const Trailers = lazy(() => import('./containers/Trailers').then().catch());
const VideoPage = lazy(() => import('./containers/Trailers/VideoPage').then().catch());
const PublishedContent = lazy(() => import('./containers/PublishedContent').then().catch());
const Crew = lazy(() => import('./containers/Crew').then().catch());
const Board = lazy(() => import('./containers/Board').then().catch());
const Gratification = lazy(() => import('./containers/Gratification').then().catch());
const PublishedVideoPage = lazy(() => import('./containers/PublishedContent/PublishedVideoPage').then().catch());
const AccountSetup = lazy(() => import('./containers/AccountSetup').then().catch());

const routes = [{
    path: '/',
    component: Home,
}, {
    path: '/walletCreation',
    component: Wallet,
}, {
    path: '/walletCreation/create',
    component: Wallet,
}, {
    path: '/interactiveVideo',
    component: InteractiveVideo,
}, {
    path: '/interactiveVideo/:id',
    component: InteractiveVideo,
}, {
    path: '/profile',
    component: UserProfile,
}, {
    path: '/login',
    component: MobileNumber,
}, {
    path: '/login/otp',
    component: MobileOTP,
}, {
    path: '/leaderboard',
    component: Leaderboard,
}, {
    path: '/user/:id',
    component: Profile,
}, {
    path: '/cast',
    component: Cast,
}, {
    path: '/profile/badges',
    component: Badges,
}, {
    path: '/profile/terms',
    component: TermsConditions,
}, {
    path: '/profile/updates',
    component: Updates,
}, {
    path: '/profile/account',
    component: AccountDetails,
}, {
    path: '/about',
    component: AboutContest,
}, {
    path: '/trailers',
    component: Trailers,
}, {
    path: '/trailers/:id',
    component: VideoPage,
}, {
    path: '/publishedContent',
    component: PublishedContent,
}, {
    path: '/crew',
    component: Crew,
}, {
    path: '/board',
    component: Board,
}, {
    path: '/gratification',
    component: Gratification,
}, {
    path: '/content/:id',
    component: PublishedVideoPage,
}, {
    path: '/accountSetup',
    component: AccountSetup,
}];

const Router = () => {
    const location = useLocation();
    // Scroll to top if path changes
    useLayoutEffect(() => {
        const scrollDiv = document.getElementById('scroll-bar');
        if (scrollDiv) { scrollDiv.scrollTop = 0; }
    }, [location.pathname]);

    return (
        <div className="main_content">
            <TopNavBar/>
            <Suspense fallback={<CircularProgress/>}>
                <div className="content_div scroll_bar" id = "scroll-bar">
                    <Routes>
                        {routes.map((route) =>
                            <Route
                                key={route.path}
                                exact
                                element={<route.component/>}
                                path={route.path}/>,
                        )}
                        <Route
                            exact
                            element={<Home/>}
                            path="*"/>
                    </Routes>
                </div>
            </Suspense>
            <NavBar/>
        </div>
    );
};

export default Router;
