import { combineReducers } from 'redux';
import { ADDRESS_SET, WALLET_MNEMONIC_SET, WALLET_SET } from '../../constants/cosmjsWallet';
import { DISCONNECT_SET } from '../../constants/wallet';

const mnemonic = (state = '', action) => {
    switch (action.type) {
    case WALLET_MNEMONIC_SET:
        return action.value;
    case DISCONNECT_SET:
        return '';
    default:
        return state;
    }
};

const address = (state = '', action) => {
    switch (action.type) {
    case ADDRESS_SET:
        return action.value;
    case DISCONNECT_SET:
        return '';
    default:
        return state;
    }
};

const wallet = (state = {}, action) => {
    switch (action.type) {
    case WALLET_SET:
        return action.value;
    case DISCONNECT_SET:
        return {};
    default:
        return state;
    }
};

export default combineReducers({
    mnemonic,
    address,
    wallet,
});
