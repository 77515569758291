import { combineReducers } from 'redux';
import {
    ACCOUNT_DATA_FETCH_ERROR,
    ACCOUNT_DATA_FETCH_IN_PROGRESS,
    ACCOUNT_DATA_FETCH_SUCCESS,
    ACCOUNT_DATA_SET,
    CONNECT_WEB3AUTH_ACCOUNT_ERROR,
    CONNECT_WEB3AUTH_ACCOUNT_IN_PROGRESS,
    CONNECT_WEB3AUTH_ACCOUNT_SUCCESS,
    CONNECTING_ACCOUNT_SET,
    DISCONNECT_ACCOUNT_SET,
    SEQUENCE_FETCH_ERROR,
    SEQUENCE_FETCH_IN_PROGRESS,
    SEQUENCE_FETCH_SUCCESS,
    SIGN_TRANSACTION_ERROR,
    SIGN_TRANSACTION_IN_PROGRESS,
    SIGN_TRANSACTION_SUCCESS,
    UPDATE_USER_SET,
} from '../../constants/web3Auth';
import { DISCONNECT_SET } from '../../constants/wallet';

const web3auth = (state = {
    inProgress: false,
    value: null,
    account: {},
    connecting: false,
}, action) => {
    switch (action.type) {
    case CONNECT_WEB3AUTH_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            connecting: true,
        };
    case CONNECT_WEB3AUTH_ACCOUNT_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case CONNECT_WEB3AUTH_ACCOUNT_ERROR:
        return {
            ...state,
            inProgress: false,
            connecting: false,
        };
    case CONNECTING_ACCOUNT_SET:
        return {
            ...state,
            connecting: action.value,
        };
    case ACCOUNT_DATA_SET:
        return {
            ...state,
            account: action.value,
        };
    case DISCONNECT_ACCOUNT_SET:
        return {
            ...state,
            value: null,
            account: {},
        };
    default:
        return state;
    }
};

const accountData = (state = {
    inProgress: false,
    value: {},
    pubkeyData: {},
    aminoPubkeyData: {},
    privKey: null,
}, action) => {
    switch (action.type) {
    case ACCOUNT_DATA_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACCOUNT_DATA_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
            pubkeyData: action.pubkeyData,
            aminoPubkeyData: action.aminoPubkeyData,
            privKey: action.privKey,
        };
    case ACCOUNT_DATA_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_ACCOUNT_SET:
        return {
            ...state,
            value: {},
            pubkeyData: {},
            aminoPubkeyData: {},
            privKey: null,
        };
    default:
        return state;
    }
};

const signTransaction = (state = {
    inProgress: false,
    value: {},
    sequence: {},
}, action) => {
    switch (action.type) {
    case SIGN_TRANSACTION_IN_PROGRESS:
    case SEQUENCE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SIGN_TRANSACTION_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case SEQUENCE_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            sequence: action.value,
        };
    case SIGN_TRANSACTION_ERROR:
    case SEQUENCE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const updateUser = (state = {
    address: '',
    data: {},
}, action) => {
    switch (action.type) {
    case UPDATE_USER_SET:
        return {
            address: action.address,
            data: action.data,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            address: '',
            data: {},
        };

    default:
        return state;
    }
};

export default combineReducers({
    accountData,
    web3auth,
    signTransaction,
    updateUser,
});
