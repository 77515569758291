import {
    ALLOWANCES_FETCH_ERROR,
    ALLOWANCES_FETCH_IN_PROGRESS,
    ALLOWANCES_FETCH_SUCCESS,
    AUTHZ_ACCOUNT_FETCH_ERROR,
    AUTHZ_ACCOUNT_FETCH_IN_PROGRESS,
    AUTHZ_ACCOUNT_FETCH_SUCCESS,
    BALANCE_FETCH_ERROR,
    BALANCE_FETCH_IN_PROGRESS,
    BALANCE_FETCH_SUCCESS,
    FEE_GRANT_ALLOWANCE_ERROR,
    FEE_GRANT_ALLOWANCE_IN_PROGRESS,
    FEE_GRANT_ALLOWANCE_SUCCESS,
    GRANT_INFO_FETCH_ERROR,
    GRANT_INFO_FETCH_IN_PROGRESS,
    GRANT_INFO_FETCH_SUCCESS,
} from '../../constants/wallet';
import Axios from 'axios';
import {
    urlFeeGrantAllowances,
    urlFetchAllowances,
    urlFetchBalance,
    urlFetchBiggBossAuthzAccount,
    urlFetchGrantInfo,
} from '../../constants/restURL';
import { customTypes } from '../../registry';
import variables from '../../utils/variables';

const fetchBalanceInProgress = () => {
    return {
        type: BALANCE_FETCH_IN_PROGRESS,
    };
};

const fetchBalanceSuccess = (value) => {
    return {
        type: BALANCE_FETCH_SUCCESS,
        value,
    };
};

const fetchBalanceError = (message) => {
    return {
        type: BALANCE_FETCH_ERROR,
        message,
    };
};

export const fetchBalance = (address) => (dispatch) => {
    dispatch(fetchBalanceInProgress());

    const url = urlFetchBalance(address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchBalanceSuccess(res.data && res.data.balances));
        })
        .catch((error) => {
            dispatch(fetchBalanceError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllowancesInProgress = () => {
    return {
        type: ALLOWANCES_FETCH_IN_PROGRESS,
    };
};

const fetchAllowancesSuccess = (value) => {
    return {
        type: ALLOWANCES_FETCH_SUCCESS,
        value,
    };
};

const fetchAllowancesError = (message) => {
    return {
        type: ALLOWANCES_FETCH_ERROR,
        message,
    };
};

export const fetchAllowances = (address, cb) => (dispatch) => {
    dispatch(fetchAllowancesInProgress());

    const url = urlFetchAllowances(address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            const auctionType = customTypes && customTypes.CreateAuction && customTypes.CreateAuction.typeUrl;
            let data = res.data && res.data.allowances && res.data.allowances.length &&
                res.data.allowances[0];
            data = data && data.allowance && data.allowance.allowed_messages;
            const msgs = data && data.find((val) => val === auctionType);
            if (!msgs && !localStorage.getItem('fee_grant_renew')) {
                localStorage.setItem('fee_grant_renew', 'true');
            }
            dispatch(fetchAllowancesSuccess(res.data && res.data.allowances));
            if (cb) {
                cb(res.data && res.data.allowances);
            }
        })
        .catch((error) => {
            dispatch(fetchAllowancesError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const feeGrantAllowanceInProgress = () => {
    return {
        type: FEE_GRANT_ALLOWANCE_IN_PROGRESS,
    };
};

const feeGrantAllowanceSuccess = (value, message) => {
    return {
        type: FEE_GRANT_ALLOWANCE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const feeGrantAllowanceError = (message) => {
    return {
        type: FEE_GRANT_ALLOWANCE_ERROR,
        message,
        variant: 'error',
    };
};

export const feeGrantAllowanceClaim = (address, cb) => (dispatch) => {
    dispatch(feeGrantAllowanceInProgress());

    const URL = urlFeeGrantAllowances(address);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(feeGrantAllowanceSuccess(res.data && res.data.result, variables.en.fee_grant_request));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(feeGrantAllowanceError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchGrantInfoInProgress = () => {
    return {
        type: GRANT_INFO_FETCH_IN_PROGRESS,
    };
};

const fetchGrantInfoSuccess = (value) => {
    return {
        type: GRANT_INFO_FETCH_SUCCESS,
        value,
    };
};

const fetchGrantInfoError = (message) => {
    return {
        type: GRANT_INFO_FETCH_ERROR,
        message,
    };
};

export const fetchGrantInfo = (address, cb) => (dispatch) => {
    dispatch(fetchGrantInfoInProgress());

    const url = urlFetchGrantInfo(address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchGrantInfoSuccess(res.data && res.data.grants));
            if (cb) {
                cb(res.data && res.data.grants);
            }
        })
        .catch((error) => {
            dispatch(fetchGrantInfoError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchAuthzAccountInProgress = () => {
    return {
        type: AUTHZ_ACCOUNT_FETCH_IN_PROGRESS,
    };
};

const fetchAuthzAccountSuccess = (value) => {
    return {
        type: AUTHZ_ACCOUNT_FETCH_SUCCESS,
        value,
    };
};

const fetchAuthzAccountError = (message) => {
    return {
        type: AUTHZ_ACCOUNT_FETCH_ERROR,
        message,
    };
};

export const fetchAuthzAccount = (cb) => (dispatch) => {
    dispatch(fetchAuthzAccountInProgress());

    const url = urlFetchBiggBossAuthzAccount();
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchAuthzAccountSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchAuthzAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};
