import * as PropTypes from 'prop-types';
import { hidePrivateKeyDialog } from '../../../actions/navBar';
import { connect } from 'react-redux';
import React from 'react';
import './index.css';
import { Dialog } from '@mui/material';
import variables from '../../../utils/variables';
import CopyButton from '../../../components/CopyButton/TextButton';

const PrivateKeyDialog = (props) => {
    const hash = props.hash;

    return (
        <Dialog
            aria-describedby="connect with Agni Sakshi"
            aria-labelledby="Connect account"
            className="dialog private_dialog"
            open={props.open}
            onClose={props.handleClose}>
            <div className="private_dialog_content">
                <h2>{variables[props.lang].private_key}</h2>
                <p>{variables[props.lang].use_private_key}</p>
                <div className="hash_text" title={hash}>
                    <p className="name">{hash}</p>
                    {hash && hash.slice(hash.length - 6, hash.length)}
                </div>
                {hash
                    ? <CopyButton data={hash}>
                        {variables[props.lang].copy}
                    </CopyButton>
                    : null}
            </div>
        </Dialog>
    );
};

PrivateKeyDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    hash: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.navBar.privateKeyDialog.open,
        hash: state.account.web3Auth.accountData.privKey,
    };
};

const actionToProps = {
    handleClose: hidePrivateKeyDialog,
};

export default connect(stateToProps, actionToProps)(PrivateKeyDialog);
