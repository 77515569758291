export const SEARCH_VALUE_SET = 'SEARCH_VALUE_SET';

export const FETCH_LEADERBOARD_BADGES_SUCCESS = 'FETCH_LEADERBOARD_BADGES_SUCCESS';
export const FETCH_LEADERBOARD_BADGES_IN_PROGRESS = 'FETCH_LEADERBOARD_BADGES_IN_PROGRESS';
export const FETCH_LEADERBOARD_BADGES_ERROR = 'FETCH_LEADERBOARD_BADGES_ERROR';

export const FETCH_LEADERBOARD_BADGES_IMAGE_IN_PROGRESS = 'FETCH_LEADERBOARD_BADGES_IMAGE_IN_PROGRESS';
export const FETCH_LEADERBOARD_BADGES_IMAGE_SUCCESS = 'FETCH_LEADERBOARD_BADGES_IMAGE_SUCCESS';
export const FETCH_LEADERBOARD_BADGES_IMAGE_ERROR = 'FETCH_LEADERBOARD_BADGES_IMAGE_ERROR';

export const USER_POSITION_FETCH_SUCCESS = 'USER_POSITION_FETCH_SUCCESS';
export const USER_POSITION_FETCH_IN_PROGRESS = 'USER_POSITION_FETCH_IN_PROGRESS';
export const USER_POSITION_FETCH_ERROR = 'USER_POSITION_FETCH_ERROR';

export const USER_POSITION_IMAGE_FETCH_SUCCESS = 'USER_POSITION_IMAGE_FETCH_SUCCESS';
export const USER_POSITION_IMAGE_FETCH_IN_PROGRESS = 'USER_POSITION_IMAGE_FETCH_IN_PROGRESS';
export const USER_POSITION_IMAGE_FETCH_ERROR = 'USER_POSITION_IMAGE_FETCH_ERROR';

export const FETCH_USER_IMAGE_IN_PROGRESS = 'FETCH_USER_IMAGE_IN_PROGRESS';
export const FETCH_USER_IMAGE_SUCCESS = 'FETCH_USER_IMAGE_SUCCESS';
export const FETCH_USER_IMAGE_ERROR = 'FETCH_USER_IMAGE_ERROR';

export const FETCH_USER_DETAILS_IN_PROGRESS = 'FETCH_USER_DETAILS_IN_PROGRESS';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_ERROR = 'FETCH_USER_DETAILS_ERROR';

export const FETCH_USER_BADGES_IN_PROGRESS = 'FETCH_USER_BADGES_IN_PROGRESS';
export const FETCH_USER_BADGES_SUCCESS = 'FETCH_USER_BADGES_SUCCESS';
export const FETCH_USER_BADGES_ERROR = 'FETCH_USER_BADGES_ERROR';

export const FETCH_USER_STREAK_IN_PROGRESS = 'FETCH_USER_STREAK_IN_PROGRESS';
export const FETCH_USER_STREAK_SUCCESS = 'FETCH_USER_STREAK_SUCCESS';
export const FETCH_USER_STREAK_ERROR = 'FETCH_USER_STREAK_ERROR';
