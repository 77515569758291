import { config } from '../config';

const URL = config.REST_URL;
const API_URL = config.API_URL;

export const urlFetchBalance = (address) => `${URL}/cosmos/bank/v1beta1/balances/${address}`;
export const urlFetchAllowances = (address) => `${URL}/cosmos/feegrant/v1beta1/allowances/${address}`;
export const urlFetchGrantInfo = (address) => `${URL}/cosmos/authz/v1beta1/grants/granter/${address}`;
export const urlFetchBiggBossAuthzAccount = () => `${API_URL}/agni-sakshi-authz-account`;
export const urlFeeGrantAllowances = (address) => `${API_URL}/${address}/fee-grant`;
