import { ADDRESS_SET, WALLET_MNEMONIC_SET, WALLET_SET } from '../../constants/cosmjsWallet';
import { config } from '../../config';
import { defaultRegistryTypes, SigningStargateClient } from '@cosmjs/stargate';
import { Registry } from '@cosmjs/proto-signing';
import { customRegistry } from '../../registry';
import { signTxError, signTxInProgress, signTxSuccess } from './wallet';
import { makeSignDoc as AminoMakeSignDoc } from '@cosmjs/amino/build/signdoc';

export const setMnemonic = (value) => {
    return {
        type: WALLET_MNEMONIC_SET,
        value,
    };
};

export const setAddress = (value) => {
    return {
        type: ADDRESS_SET,
        value,
    };
};

export const setWallet = (value) => {
    return {
        type: WALLET_SET,
        value,
    };
};

export const cosmjsMsgSign = (tx, address, wallet, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        try {
            const myRegistry = new Registry([...defaultRegistryTypes, ...customRegistry]);
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                wallet,
                {
                    prefix: config.PREFIX,
                    registry: myRegistry,
                },
            );

            client.signAndBroadcast(
                address,
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                tx.memo,
            ).then((result) => {
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                    cb(null);
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result);
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
                cb(null);
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
            cb(null);
        }
    })();
};

export const cosmjsMsgSignAmino = (tx, address, wallet, cb) => (dispatch) => {
    dispatch(signTxInProgress());
    (async () => {
        try {
            const myRegistry = new Registry([...defaultRegistryTypes, ...customRegistry]);
            const client = await SigningStargateClient.connectWithSigner(
                config.RPC_URL,
                wallet,
                {
                    prefix: config.PREFIX,
                    registry: myRegistry,
                },
            );

            const account = {};
            try {
                const {
                    accountNumber,
                    sequence,
                } = await client.getSequence(address);
                account.accountNumber = accountNumber;
                account.sequence = sequence;
            } catch (e) {
                account.accountNumber = 0;
                account.sequence = 0;
            }

            const signDoc = AminoMakeSignDoc(
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                config.CHAIN_ID,
                tx.memo,
                account.accountNumber,
                account.sequence,
            );

            wallet.signAmino(
                address,
                signDoc,
            ).then((result) => {
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTxError(result.log || result.rawLog));
                    cb(null);
                } else {
                    dispatch(signTxSuccess(result));
                    cb(result);
                }
            }).catch((error) => {
                dispatch(signTxError(error && error.message));
                cb(null);
            });
        } catch (e) {
            dispatch(signTxError(e && e.message));
            cb(null);
        }
    })();
};
