import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import variables from '../../../utils/variables';
import { Button } from '@mui/material';
import { showMessage } from '../../../actions/snackbar';
import {
    fetchAccountData,
    fetchSequence,
    initializeWeb3AuthChain,
    setUpdateUser,
    signTransactionAmino,
} from '../../../actions/account/Web3Auth';
import { feeGrantAllowanceClaim, fetchAllowances, fetchBalance } from '../../../actions/account/BCDetails';
import { config } from '../../../config';
import moment from 'moment';
import { hideConnectWalletDialog, showPrivateKeyDialog, showProcessingDialog } from '../../../actions/navBar';
import { connectBCAccount, fetchProfileDetails, verifyAccount } from '../../../actions/account';
import CircularProgress from '../../../components/CircularProgress';
import { fetchProfileImage } from '../../../actions/profile';

const ConnectButton = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const {
        initializeChain,
        web3auth,
    } = props;

    useEffect(() => {
        if (!web3auth) {
            initializeChain();
        }
    }, [initializeChain, web3auth]);

    const handleUpdate = (address) => {
        props.web3auth && props.web3auth.getUserInfo().then((result) => {
            const data = {
                emailAddress: result.email,
            };

            props.setUpdateUser(address, data);
        }).catch((error) => {
            console.log('', error);
        });
    };

    // const handleLogout = () => {
    //     props.web3auth.logout();
    // };

    const handleTransaction = (result, res, pubkeyData) => {
        props.fetchAllowances(result.address, (allowances) => {
            const granterInfo = {};
            if (allowances && allowances.length) {
                allowances.map((val) => {
                    if (val && val.allowance && val.allowance.spend_limit && val.allowance.spend_limit.length) {
                        const amount = val.allowance.spend_limit.find((val1) => (val1.denom === config.COIN_MINIMAL_DENOM) &&
                            val1.amount && (val1.amount > 0.1 * (10 ** config.COIN_DECIMALS)));
                        if (amount && amount.amount) {
                            granterInfo.granter = val.granter;
                            granterInfo.amount = amount.amount / 10 ** config.COIN_DECIMALS;
                            granterInfo.expiration = val.allowance.expiration;
                        }
                    } else if (val && val.allowance && val.allowance.allowed_messages &&
                        val.allowance.allowed_messages.length) {
                        if (val && val.allowance && val.allowance.allowance &&
                            val.allowance.allowance.spend_limit && val.allowance.allowance.spend_limit.length) {
                            const amount = val.allowance.allowance.spend_limit.find((val1) => (val1.denom === config.COIN_MINIMAL_DENOM) &&
                                val1.amount && (val1.amount > 0.1 * (10 ** config.COIN_DECIMALS)));
                            if (amount && amount.amount) {
                                granterInfo.granter = val.granter;
                                granterInfo.amount = amount.amount / 10 ** config.COIN_DECIMALS;
                                granterInfo.expiration = val && val.allowance && val.allowance.allowance &&
                                    val.allowance.allowance.expiration;
                            }
                        }
                    }

                    return null;
                });
            }

            if (granterInfo && granterInfo.amount && (granterInfo.amount > 0) &&
                granterInfo.expiration && moment().diff(granterInfo && granterInfo.expiration) < 0) {
                handleTx(result, res, pubkeyData);
            }

            // const time = setInterval(() => {
            //     props.feeGrantAllowanceClaim(result.address, (claimResult) => {
            //         if (claimResult && (claimResult.tx_hash || claimResult.tx_hash === null)) {
            //             clearInterval(time);
            //             props.fetchAllowances(result.address);
            //             handleTx(result, res, pubkeyData);
            //         }
            //     });
            // }, 10000);
        });
    };

    const handleTx = (result, res, pubkeyData) => {
        props.fetchSequence(result.address, (sequenceResult) => {
            let accountNumber = '0';
            let sequence = '0';

            if (sequenceResult) {
                accountNumber = sequenceResult && sequenceResult.result && sequenceResult.result.value &&
                sequenceResult.result.value.account_number ? sequenceResult.result.value.account_number : '0';
                sequence = sequenceResult && sequenceResult.result && sequenceResult.result.value &&
                sequenceResult.result.value.sequence ? sequenceResult.result.value.sequence : '0';
            }

            const account = {
                ...result,
                accountNumber: accountNumber,
                sequence: sequence,
            };

            const tx = {
                msg: {
                    type: 'omniflix/MsgSign',
                    value: {
                        address: result.address,
                    },
                },
                fee: {
                    amount: [{
                        amount: String(0),
                        denom: config.COIN_MINIMAL_DENOM,
                    }],
                    gas: String(1),
                },
                memo: res['auth_code'],
            };

            props.signTransactionAmino(pubkeyData, account, tx, (result1) => {
                if (result1) {
                    const data = {
                        authCode: res['auth_code'],
                        sign: result1.signature,
                    };

                    props.verifyAccount(res._id, data, (error) => {
                        setInProgress(false);
                        if (!error) {
                            let count = 1;
                            props.showProcessingDialog(
                                {},
                                count,
                                false,
                            );
                            const time = setInterval(() => {
                                switch (count) {
                                case 1:
                                    break;
                                case 2:
                                    break;
                                default:
                                    break;
                                }

                                if (count === 3) {
                                    props.showProcessingDialog(
                                        {},
                                        5,
                                        true,
                                    );
                                    clearInterval(time);

                                    return;
                                }

                                if (count < 3) {
                                    props.showProcessingDialog(
                                        {},
                                        count + 1,
                                        false,
                                    );
                                }

                                count++;
                            }, 2000);
                            props.fetchProfileDetails();
                            props.fetchProfileImage();
                            props.hideConnectDialog();
                            localStorage.setItem('agni_sakshi_web3_auth', 'web3Auth');
                            localStorage.setItem('agni_sakshi_address', result.address);
                            props.fetchBalance(result.address);
                            // props.showPrivateKeyDialog();
                            handleTransaction(result, res, pubkeyData);
                        }
                    });
                }
            });
        });
    };

    const verify = () => {
        if (!props.web3auth) {
            props.showMessage('web3auth not initialized yet');

            return;
        }

        setInProgress(true);
        props.fetchAccountData(props.web3auth, (result, pubkeyData) => {
            if (result) {
                const data = {
                    bcAccountAddress: result.address,
                };
                props.connectBCAccount(data, (res) => {
                    if (res) {
                        if (window.keplr) {
                            window.keplr.defaultOptions = {
                                sign: {
                                    preferNoSetFee: true,
                                    preferNoSetMemo: true,
                                },
                            };
                        }

                        handleTx(result, res, pubkeyData);
                    } else {
                        setInProgress(false);
                    }
                });
                handleUpdate(result.address);
                // handleLogout();
            } else {
                setInProgress(false);
            }
        });
    };

    const connectInProgress = props.inProgress || inProgress;
    return (
        <Button className="connect_with_email" onClick={verify}>
            {connectInProgress
                ? <CircularProgress className="full_screen"/>
                : null}
            {variables[props.lang]['connect_with_email']}
        </Button>
    );
};

ConnectButton.propTypes = {
    connectBCAccount: PropTypes.func.isRequired,
    emailValue: PropTypes.string.isRequired,
    feeGrantAllowanceClaim: PropTypes.func.isRequired,
    fetchAccountData: PropTypes.func.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    fetchProfileImage: PropTypes.func.isRequired,
    fetchSequence: PropTypes.func.isRequired,
    hideConnectDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeChain: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    setUpdateUser: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    showPrivateKeyDialog: PropTypes.func.isRequired,
    showProcessingDialog: PropTypes.func.isRequired,
    signTransactionAmino: PropTypes.func.isRequired,
    verifyAccount: PropTypes.func.isRequired,
    web3auth: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        emailValue: state.account.email.value,
        lang: state.language,
        web3auth: state.account.web3Auth.web3auth.value,
        inProgress: state.account.web3Auth.web3auth.inProgress,
    };
};

const actionsToProps = {
    connectBCAccount,
    feeGrantAllowanceClaim,
    fetchAccountData,
    fetchAllowances,
    fetchBalance,
    fetchProfileDetails,
    fetchSequence,
    showMessage,
    initializeChain: initializeWeb3AuthChain,
    hideConnectDialog: hideConnectWalletDialog,
    showPrivateKeyDialog,
    showProcessingDialog,
    verifyAccount,
    signTransactionAmino,
    setUpdateUser,
    fetchProfileImage,
};

export default connect(stateToProps, actionsToProps)(ConnectButton);
