import {
    CONNECT_WALLET_DIALOG_HIDE,
    CONNECT_WALLET_DIALOG_SHOW,
    LOGIN_OTP_ERROR,
    LOGIN_OTP_IN_PROGRESS,
    LOGIN_OTP_SUCCESS,
    LOGIN_USER_ERROR,
    LOGIN_USER_IN_PROGRESS,
    LOGIN_USER_SUCCESS,
    NAVBAR_TAB_VALUE_SET,
    PRIVATE_KEY_DIALOG_HIDE,
    PRIVATE_KEY_DIALOG_SHOW,
    PROCESSING_DIALOG_HIDE,
    PROCESSING_DIALOG_SHOW,
    WEB3AUTH_ID_VALUE_SET,
} from '../constants/navBar';
import { urlOTPLogin, USER_LOGIN_URL } from '../constants/url';
import Axios from 'axios';

export const setNavBarTab = (value) => {
    return {
        type: NAVBAR_TAB_VALUE_SET,
        value,
    };
};

export const showConnectWalletDialog = (value) => {
    return {
        type: CONNECT_WALLET_DIALOG_SHOW,
        value,
    };
};

export const hideConnectWalletDialog = () => {
    return {
        type: CONNECT_WALLET_DIALOG_HIDE,
    };
};

export const setWeb3AuthID = (value, valid) => {
    return {
        type: WEB3AUTH_ID_VALUE_SET,
        value,
        valid,
    };
};

export const showPrivateKeyDialog = (value) => {
    return {
        type: PRIVATE_KEY_DIALOG_SHOW,
        value,
    };
};

export const hidePrivateKeyDialog = () => {
    return {
        type: PRIVATE_KEY_DIALOG_HIDE,
    };
};

export const showProcessingDialog = (value, step, success) => {
    return {
        type: PROCESSING_DIALOG_SHOW,
        value,
        step,
        success,
    };
};

export const hideProcessingDialog = () => {
    return {
        type: PROCESSING_DIALOG_HIDE,
    };
};

export const userLoginSuccess = (result, message) => {
    return {
        type: LOGIN_USER_SUCCESS,
        result,
        message,
    };
};

export const userLoginError = (message) => {
    return {
        type: LOGIN_USER_ERROR,
        message,
    };
};

export const userLoginInProgress = () => {
    return {
        type: LOGIN_USER_IN_PROGRESS,
    };
};

export const loginUserPost = (data, cb) => (dispatch) => {
    dispatch(userLoginInProgress());

    Axios.post(USER_LOGIN_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    }).then((res) => {
        if (res && res.data && res.data.result) {
            dispatch(userLoginSuccess(res.data.result, 'Please Enter proper number'));
            cb(res.data.result);
        }
    }).catch((error) => {
        dispatch(userLoginError(error.response ? error.response.data.message : error.message,
        ));
    });
};

export const otpLoginSuccess = (result, message) => {
    return {
        type: LOGIN_OTP_SUCCESS,
        result,
        message,
    };
};

export const otpLoginError = (message) => {
    return {
        type: LOGIN_OTP_ERROR,
        message,
    };
};

export const otpLoginInProgress = () => {
    return {
        type: LOGIN_OTP_IN_PROGRESS,
    };
};

export const loginOTPPost = (id, data, cb) => (dispatch) => {
    dispatch(otpLoginInProgress());

    const url = urlOTPLogin(id);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    }).then((res) => {
        if (res && res.data && res.data.result) {
            dispatch(otpLoginSuccess(res.data.result, 'Success'));
            localStorage.setItem('acToken_agni_sakshi', res.data.result.access_token);
            localStorage.setItem('rfToken_agni_sakshi', res.data.result.refresh_token);
            cb(res.data.result);
        }
    }).catch((error) => {
        dispatch(otpLoginError(
            error.message
                ? error.message
                : error.response
                    ? error.response && error.response.message
                        ? error.response.message
                        : error.response.data.message
                    : error.message,
        ));
    });
};
