import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showMessage } from '../../../actions/snackbar';
import logo from '../../../assets/wallets/leap.svg';
import { hideConnectWalletDialog, showProcessingDialog } from '../../../actions/navBar';
import { fetchAllowances, fetchBalance } from '../../../actions/account/BCDetails';
import { aminoSignTx, initializeChain, initializeLeap } from '../../../actions/account/wallet';
import withRouter from '../../../components/WithRouter';
import { Button } from '@mui/material';
import CircularProgress from '../../../components/CircularProgress';
import { config } from '../../../config';
import { connectBCAccount, fetchProfileDetails, verifyAccount } from '../../../actions/account';
import { fetchProfileImage } from '../../../actions/profile';

const LeapConnectButton = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const initializeLeap = () => {
        setInProgress(true);
        props.initializeLeap((address) => {
            if (!address) {
                window.onload = () => initializeLeap();
                setInProgress(false);
                return;
            }
            if (address && address.length && address[0] && address[0].address) {
                const data = {
                    bcAccountAddress: address[0].address,
                };
                props.connectBCAccount(data, (res) => {
                    if (res) {
                        if (window.leap) {
                            window.leap.defaultOptions = {
                                sign: {
                                    preferNoSetFee: true,
                                    preferNoSetMemo: true,
                                },
                            };
                        }
                        const tx = {
                            msg: {
                                type: 'omniflix/MsgSign',
                                value: {
                                    address: address[0].address,
                                },
                            },
                            fee: {
                                amount: [{
                                    amount: String(0),
                                    denom: config.COIN_MINIMAL_DENOM,
                                }],
                                gas: String(1),
                            },
                            preferNoSetFee: true,
                            memo: res['auth_code'],
                        };

                        props.aminoSignTx(tx, address[0].address, true, (result) => {
                            if (result) {
                                const data = {
                                    authCode: res['auth_code'],
                                    sign: result.signature,
                                };

                                props.verifyAccount(res._id, data, (error) => {
                                    setInProgress(false);
                                    if (!error) {
                                        props.hideConnectDialog();
                                        localStorage.setItem('agni_sakshi_address', address[0].address);
                                        props.fetchBalance(address[0].address);
                                        props.fetchAllowances(address[0].address);
                                        props.fetchProfileDetails();
                                        props.fetchProfileImage();
                                        props.hideConnectDialog();
                                        localStorage.setItem('agni_sakshi_leap', 'leap');
                                        props.showProcessingDialog({}, 5, true);
                                        if (window.leap) {
                                            window.leap.defaultOptions = {};
                                        }
                                    }
                                });
                            } else {
                                setInProgress(false);
                            }
                        });
                    } else {
                        setInProgress(false);
                    }
                });
            } else {
                setInProgress(false);
            }
        });
    };

    const connectInProgress = props.inProgress || inProgress;
    return (
        <Button
            className="leap_button"
            disabled={connectInProgress}
            variant="contained"
            onClick={initializeLeap}>
            {connectInProgress
                ? <CircularProgress className="full_screen"/>
                : null}
            <img alt="logo" src={logo}/>
        </Button>
    );
};

LeapConnectButton.propTypes = {
    allowances: PropTypes.array.isRequired,
    allowancesInProgress: PropTypes.bool.isRequired,
    aminoSignTx: PropTypes.func.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    connectBCAccount: PropTypes.func.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    fetchProfileImage: PropTypes.func.isRequired,
    hideConnectDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeLeap: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    showMessage: PropTypes.func.isRequired,
    showProcessingDialog: PropTypes.func.isRequired,
    verifyAccount: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        allowances: state.account.bc.allowances.value,
        allowancesInProgress: state.account.bc.allowances.inProgress,
        inProgress: state.account.connection.inProgress,
        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        lang: state.language,
    };
};

const actionsToProps = {
    aminoSignTx,
    connectBCAccount,
    fetchAllowances,
    fetchBalance,
    fetchProfileDetails,
    initializeChain,
    initializeLeap,
    showMessage,
    hideConnectDialog: hideConnectWalletDialog,
    verifyAccount,
    showProcessingDialog,
    fetchProfileImage,
};

export default withRouter(connect(stateToProps, actionsToProps)(LeapConnectButton));
