export const CONNECT_KEPLR_ACCOUNT_IN_PROGRESS = 'CONNECT_KEPLR_ACCOUNT_IN_PROGRESS';
export const CONNECT_KEPLR_ACCOUNT_SUCCESS = 'CONNECT_KEPLR_ACCOUNT_SUCCESS';
export const CONNECT_KEPLR_ACCOUNT_ERROR = 'CONNECT_KEPLR_ACCOUNT_ERROR';

export const KEPLR_ACCOUNT_KEYS_SET = 'KEPLR_ACCOUNT_KEYS_SET';

export const CONNECT_LEAP_ACCOUNT_IN_PROGRESS = 'CONNECT_LEAP_ACCOUNT_IN_PROGRESS';
export const CONNECT_LEAP_ACCOUNT_SUCCESS = 'CONNECT_LEAP_ACCOUNT_SUCCESS';
export const CONNECT_LEAP_ACCOUNT_ERROR = 'CONNECT_LEAP_ACCOUNT_ERROR';

export const BALANCE_FETCH_IN_PROGRESS = 'BALANCE_FETCH_IN_PROGRESS';
export const BALANCE_FETCH_SUCCESS = 'BALANCE_FETCH_SUCCESS';
export const BALANCE_FETCH_ERROR = 'BALANCE_FETCH_ERROR';

export const ALLOWANCES_FETCH_IN_PROGRESS = 'ALLOWANCES_FETCH_IN_PROGRESS';
export const ALLOWANCES_FETCH_SUCCESS = 'ALLOWANCES_FETCH_SUCCESS';
export const ALLOWANCES_FETCH_ERROR = 'ALLOWANCES_FETCH_ERROR';

export const FEE_GRANT_ALLOWANCE_IN_PROGRESS = 'FEE_GRANT_ALLOWANCE_IN_PROGRESS';
export const FEE_GRANT_ALLOWANCE_SUCCESS = 'FEE_GRANT_ALLOWANCE_SUCCESS';
export const FEE_GRANT_ALLOWANCE_ERROR = 'FEE_GRANT_ALLOWANCE_ERROR';

export const TX_SIGN_IN_PROGRESS = 'TX_SIGN_IN_PROGRESS';
export const TX_SIGN_SUCCESS = 'TX_SIGN_SUCCESS';
export const TX_SIGN_ERROR = 'TX_SIGN_ERROR';

export const TX_SIGN_AND_BROAD_CAST_IN_PROGRESS = 'TX_SIGN_AND_BROAD_CAST_IN_PROGRESS';
export const TX_SIGN_AND_BROAD_CAST_SUCCESS = 'TX_SIGN_AND_BROAD_CAST_SUCCESS';
export const TX_SIGN_AND_BROAD_CAST_ERROR = 'TX_SIGN_AND_BROAD_CAST_ERROR';

export const TX_HASH_FETCH_IN_PROGRESS = 'TX_HASH_FETCH_CAST_IN_PROGRESS';
export const TX_HASH_FETCH_SUCCESS = 'TX_HASH_FETCH_CAST_SUCCESS';
export const TX_HASH_FETCH_ERROR = 'TX_HASH_FETCH_CAST_ERROR';

export const TX_HASH_IN_PROGRESS_FALSE_SET = 'TX_HASH_IN_PROGRESS_FALSE_SET';

export const DISCONNECT_SET = 'DISCONNECT_SET';

export const GRANT_INFO_FETCH_SUCCESS = 'GRANT_INFO_FETCH_SUCCESS';
export const GRANT_INFO_FETCH_IN_PROGRESS = 'GRANT_INFO_FETCH_IN_PROGRESS';
export const GRANT_INFO_FETCH_ERROR = 'GRANT_INFO_FETCH_ERROR';

export const AUTHZ_ACCOUNT_FETCH_SUCCESS = 'AUTHZ_ACCOUNT_FETCH_SUCCESS';
export const AUTHZ_ACCOUNT_FETCH_IN_PROGRESS = 'AUTHZ_ACCOUNT_FETCH_IN_PROGRESS';
export const AUTHZ_ACCOUNT_FETCH_ERROR = 'AUTHZ_ACCOUNT_FETCH_ERROR';
