import { config } from '../config';

const URL = config.API_URL;

export const CONNECT_ACCOUNT_URL = `${URL}/me/connect-bc-account`;
export const ACCESS_TOKEN_URL = `${URL}/user/auth/refresh-token`;
export const PROFILE_DETAILS_URL = `${URL}/me/profile/details`;
export const PROFILE_IMAGE_URL = `${URL}/me/profile/image`;
export const USER_LOGIN_URL = `${URL}/users/login`;
export const LOGIN_OTP_URL = `${URL}/users/login`;
export const FEE_GRANT_URL = `${URL}/me/fee-grant`;
export const urlOTPLogin = (userId) =>
    `${URL}/users/${userId}/verify-login`;

export const urlVerifyAccount = () =>
    `${URL}/me/verify-bc-account`;

export const getInteractiveVideosURL = (limit) => {
    const params = [];
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/channels/${config.CHANNEL_ID}/interactive-videos?${params.join('&')}`;
};
export const getInteractiveVideoURL = (id) => `${URL}/interactive-videos/${id}`;
export const addInteractiveVideoSessionURL = (id) => `${URL}/interactive-videos/${id}/sessions`;
export const saveInteractiveVideoAnswerURL = (interactiveVideoId, sessionId) =>
    `${URL}/interactive-videos/${interactiveVideoId}/sessions/${sessionId}/answers`;
export const completeInteractiveVideoSessionURL = (interactiveVideoId, sessionId) =>
    `${URL}/interactive-videos/${interactiveVideoId}/sessions/${sessionId}/complete`;
export const getParticipatedDetailsURL = (interactiveVideoId, address) => `${URL}/interactive-videos/${interactiveVideoId}/participants?accountAddress=${address}`;
export const getAllParticipantsDetailsURL = (interactiveVideoId) => `${URL}/interactive-videos/${interactiveVideoId}/participants`;

export const urlFetchProfileBadges = (skip, limit) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/me/interactive-video-participant-badges?${params.join('&')}`;
};
export const urlFetchProfileStreak = (skip, limit) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/me/interactive-video-participant-streak?${params.join('&')}`;
};
// Leaderboard
export const urlFetchLeaderboardBadges = (skip, limit) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/leaderboard-rank?${params.join('&')}`;
};

export const urlFetchUserPosition = (address) => {
    const params = [];
    if (address && address !== '') {
        params.push(`accountAddress=${address}`);
    }

    return `${URL}/leaderboard-rank?${params.join('&')}`;
};
export const claimBadgeURL = (id) => `${URL}/me/interactive-video-participant-badges?interactiveVideoId=${id}`;
export const urlUserImage = (id) => `${URL}/users/${id}/profile/image`;
export const urlUserDetails = (id) => `${URL}/users/${id}/profile/details`;
export const urlUserBadges = (accountAddress, skip, limit) => {
    const params = [];
    if (accountAddress) {
        params.push(`accountAddress=${accountAddress}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/interactive-video-participant-badges?${params.join('&')}`;
};
export const urlUserStreak = (accountAddress) => {
    const params = [];
    if (accountAddress) {
        params.push(`accountAddress=${accountAddress}`);
    }

    return `${URL}/interactive-video-participant-streaks?${params.join('&')}`;
};

// housemates
export const urlHousematesImage = (id) => `${URL}/housemates/${id}/profile/image`;
export const urlFetchHousemates = (skip, limit) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/housemates?${params.join('&')}`;
};

// cast
export const urlFetchCast = (skip, limit) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/actors?${params.join('&')}`;
};

// crew
export const urlFetchCrew = (skip, limit) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/crews?${params.join('&')}`;
};

// Trailers
export const urlFetchTrailers = (skip, limit) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/trailers?${params.join('&')}`;
};

export const urlFetchTrailer = (id) => {
    return `${URL}/trailers/${id}`;
};

// Advertisement
export const urlFetchAdvertisements = (skip, limit) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/advertisements?${params.join('&')}`;
};
