export const EMAIL_ADDRESS_SET = 'EMAIL_ADDRESS_SET';

export const CONNECT_BC_ACCOUNT_IN_PROGRESS = 'CONNECT_BC_ACCOUNT_IN_PROGRESS';
export const CONNECT_BC_ACCOUNT_SUCCESS = 'CONNECT_BC_ACCOUNT_SUCCESS';
export const CONNECT_BC_ACCOUNT_ERROR = 'CONNECT_BC_ACCOUNT_ERROR';

export const VERIFY_ACCOUNT_IN_PROGRESS = 'VERIFY_ACCOUNT_IN_PROGRESS';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_ERROR = 'VERIFY_ACCOUNT_ERROR';

export const ACCESS_TOKEN_FETCH_IN_PROGRESS = 'ACCESS_TOKEN_FETCH_IN_PROGRESS';
export const ACCESS_TOKEN_FETCH_SUCCESS = 'ACCESS_TOKEN_FETCH_SUCCESS';
export const ACCESS_TOKEN_FETCH_ERROR = 'ACCESS_TOKEN_FETCH_ERROR';

export const PROFILE_DETAILS_FETCH_IN_PROGRESS = 'PROFILE_DETAILS_FETCH_IN_PROGRESS';
export const PROFILE_DETAILS_FETCH_SUCCESS = 'PROFILE_DETAILS_FETCH_SUCCESS';
export const PROFILE_DETAILS_FETCH_ERROR = 'PROFILE_DETAILS_FETCH_ERROR';

export const FEE_GRANT_FETCH_IN_PROGRESS = 'FEE_GRANT_FETCH_IN_PROGRESS';
export const FEE_GRANT_FETCH_SUCCESS = 'FEE_GRANT_FETCH_SUCCESS';
export const FEE_GRANT_FETCH_ERROR = 'FEE_GRANT_FETCH_ERROR';
