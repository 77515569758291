import {
    FETCH_TRAILERS_IN_PROGRESS,
    FETCH_TRAILERS_SUCCESS,
    FETCH_TRAILERS_ERROR,
    FETCH_TRAILER_IN_PROGRESS,
    FETCH_TRAILER_SUCCESS,
    FETCH_TRAILER_ERROR,
} from '../constants/trailers';
import { combineReducers } from 'redux';
import { DEFAULT_SKIP, DEFAULT_LIMIT } from '../config';

const trailersList = (state = {
    result: [],
    inProgress: false,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
}, action) => {
    switch (action.type) {
    case FETCH_TRAILERS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_TRAILERS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
        };
    case FETCH_TRAILERS_ERROR:
        return {
            inProgress: false,
        };
    default:
        return state;
    }
};

const singleTrailer = (state = {
    result: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case FETCH_TRAILER_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_TRAILER_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    case FETCH_TRAILER_ERROR:
        return {
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    trailersList,
    singleTrailer,
});
