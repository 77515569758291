import { combineReducers } from 'redux';
import {
    FETCH_PROFILE_BADGES_ERROR,
    FETCH_PROFILE_BADGES_IN_PROGRESS,
    FETCH_PROFILE_BADGES_SUCCESS,
    FETCH_PROFILE_IMAGE_ERROR,
    FETCH_PROFILE_IMAGE_IN_PROGRESS,
    FETCH_PROFILE_IMAGE_SUCCESS,
    FETCH_PROFILE_STREAK_ERROR,
    FETCH_PROFILE_STREAK_IN_PROGRESS,
    FETCH_PROFILE_STREAK_SUCCESS,
    LOCATION_VALUE_SET,
    MOBILE_NUMBER_SET,
    MOBILE_OTP_SET,
    PHONE_LAST_DIGIT_SET,
    REFER_DIALOG_HIDE,
    REFER_DIALOG_SHOW,
    SELECT_LANGUAGE_DIALOG_HIDE,
    SELECT_LANGUAGE_DIALOG_SHOW,
    VERIFIED_ACCOUNT_DIALOG_HIDE,
    VERIFIED_ACCOUNT_DIALOG_SHOW,
    WON_BADGE_DIALOG_HIDE,
    WON_BADGE_DIALOG_SHOW,
    UPLOAD_PROFILE_IMAGE_ERROR,
    UPLOAD_PROFILE_IMAGE_IN_PROGRESS,
    UPLOAD_PROFILE_IMAGE_SUCCESS,
    USER_NAME_SET,
    UPLOAD_USER_DETAILS_SUCCESS,
    UPLOAD_USER_DETAILS_IN_PROGRESS,
    UPLOAD_USER_DETAILS_ERROR,
} from '../constants/profile';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../config';
import { DISCONNECT_SET } from '../constants/wallet';

const mobileNumber = (state = {
    value: '',
    validation: false,
}, action) => {
    switch (action.type) {
    case MOBILE_NUMBER_SET:
        return {
            ...state,
            value: action.value,
            validation: action.validation,
        };
    default:
        return state;
    }
};

const locationValue = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case LOCATION_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const mobileOTP = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MOBILE_OTP_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const phoneLastDigit = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case PHONE_LAST_DIGIT_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const verifiedAccountDialog = (state = {
    value: {},
    open: false,
}, action) => {
    switch (action.type) {
    case VERIFIED_ACCOUNT_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case VERIFIED_ACCOUNT_DIALOG_HIDE:
        return {
            ...state,
            value: {},
            open: false,
        };
    default:
        return state;
    }
};

const wonBadgeDialog = (state = {
    value: {},
    open: false,
}, action) => {
    switch (action.type) {
    case WON_BADGE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case WON_BADGE_DIALOG_HIDE:
        return {
            ...state,
            value: {},
            open: false,
        };
    default:
        return state;
    }
};

const selectLanguageDialog = (state = {
    value: {},
    open: false,
}, action) => {
    switch (action.type) {
    case SELECT_LANGUAGE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case SELECT_LANGUAGE_DIALOG_HIDE:
        return {
            ...state,
            value: {},
            open: false,
        };
    default:
        return state;
    }
};

const profileBadges = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_PROFILE_BADGES_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PROFILE_BADGES_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    case FETCH_PROFILE_BADGES_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const referDialog = (state = {
    value: {},
    open: false,
}, action) => {
    switch (action.type) {
    case REFER_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case REFER_DIALOG_HIDE:
        return {
            ...state,
            value: {},
            open: false,
        };
    default:
        return state;
    }
};

const profileImage = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_PROFILE_IMAGE_IN_PROGRESS:
        // case UPDATE_PROFILE_IMAGE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PROFILE_IMAGE_SUCCESS:
        // case UPDATE_PROFILE_IMAGE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_PROFILE_IMAGE_ERROR:
        // case UPDATE_PROFILE_IMAGE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const profileImageUpload = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case UPLOAD_PROFILE_IMAGE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPLOAD_PROFILE_IMAGE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case UPLOAD_PROFILE_IMAGE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const profileStreak = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_PROFILE_STREAK_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PROFILE_STREAK_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case FETCH_PROFILE_STREAK_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const profileUserName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case USER_NAME_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const uploadUserDetails = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case UPLOAD_USER_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPLOAD_USER_DETAILS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case UPLOAD_USER_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    mobileNumber,
    locationValue,
    mobileOTP,
    phoneLastDigit,
    verifiedAccountDialog,
    wonBadgeDialog,
    selectLanguageDialog,
    profileBadges,
    referDialog,
    profileImage,
    profileStreak,
    profileImageUpload,
    uploadUserDetails,
    profileUserName,
});
