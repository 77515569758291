import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import variables from '../../../utils/variables';
import { hideProcessingDialog } from '../../../actions/navBar';
import key from '../../../assets/navBar/key.gif';
import { Button, Dialog } from '@mui/material';
import { ReactComponent as Icon1 } from '../../../assets/verify/1.svg';
import { ReactComponent as Icon2 } from '../../../assets/verify/2.svg';
import { ReactComponent as Icon3 } from '../../../assets/verify/3.svg';
import { ReactComponent as Icon4 } from '../../../assets/verify/4.svg';
import { ReactComponent as Icon5 } from '../../../assets/verify/5.svg';
import './index.css';
import { ReactComponent as ApprovedIcon } from '../../../assets/navBar/approved.svg';
import CopyButton from '../../../components/CopyButton';
import copyIcon from '../../../assets/copy.svg';

const ProcessingDialog = (props) => {
    const handleSteps = () => {
        switch (props.step) {
        case 1:
            return <Icon1/>;
        case 2:
            return <Icon2/>;
        case 3:
            return <Icon3/>;
        case 4:
            return <Icon4/>;
        case 5:
            return <Icon5/>;
        default:
            return <Icon1/>;
        }
    };

    const handleClose = () => {
        if (props.step === 5) {
            props.hideDialog();
        }
    };

    return (
        <Dialog
            aria-describedby="block-chain-processing"
            aria-labelledby="processing"
            className="dialog processing_dialog"
            open={props.open}
            onClose={handleClose}>
            {props.success
                ? <div className="approved_content">
                    <ApprovedIcon/>
                    <div>
                        <p>{variables[props.lang].your_bc_address}</p>
                        <div>
                            <span>{props.address}</span>
                            <CopyButton data={props.address} icon={copyIcon}/>
                        </div>
                    </div>
                    <Button onClick={props.hideDialog}>
                        <p>
                            {variables[props.lang].okay}
                        </p>
                    </Button>
                </div>
                : <div className="creating_public_key">
                    <h2>{variables[props.lang].create_key_pair}</h2>
                    <div>
                        <img alt="logo" src={key}/>
                        {handleSteps()}
                    </div>
                </div>}
        </Dialog>
    );
};

ProcessingDialog.propTypes = {
    address: PropTypes.string.isRequired,
    hideDialog: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    step: PropTypes.number.isRequired,
    success: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        address: state.account.connection.address,
        lang: state.language,
        open: state.navBar.processingDialog.open,
        value: state.navBar.processingDialog.value,
        step: state.navBar.processingDialog.step,
        success: state.navBar.processingDialog.success,
    };
};

const actionToProps = {
    hideDialog: hideProcessingDialog,
};

export default connect(stateToProps, actionToProps)(ProcessingDialog);
