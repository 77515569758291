import {
    ACCESS_TOKEN_FETCH_ERROR,
    ACCESS_TOKEN_FETCH_IN_PROGRESS,
    ACCESS_TOKEN_FETCH_SUCCESS,
    CONNECT_BC_ACCOUNT_ERROR,
    CONNECT_BC_ACCOUNT_IN_PROGRESS,
    CONNECT_BC_ACCOUNT_SUCCESS,
    EMAIL_ADDRESS_SET,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_IN_PROGRESS,
    VERIFY_ACCOUNT_SUCCESS,
    FEE_GRANT_FETCH_ERROR,
    FEE_GRANT_FETCH_IN_PROGRESS,
    FEE_GRANT_FETCH_SUCCESS,
} from '../../constants/account';
import Axios from 'axios';
import { ACCESS_TOKEN_URL, CONNECT_ACCOUNT_URL, PROFILE_DETAILS_URL, urlVerifyAccount, FEE_GRANT_URL } from '../../constants/url';

export const setEmailAddress = (value, valid) => {
    return {
        type: EMAIL_ADDRESS_SET,
        value,
        valid,
    };
};

const connectBCAccountInProgress = () => {
    return {
        type: CONNECT_BC_ACCOUNT_IN_PROGRESS,
    };
};

const connectBCAccountSuccess = (value) => {
    return {
        type: CONNECT_BC_ACCOUNT_SUCCESS,
        value,
    };
};

const connectBCAccountError = (message) => {
    return {
        type: CONNECT_BC_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const connectBCAccount = (data, cb) => (dispatch) => {
    dispatch(connectBCAccountInProgress());

    Axios.post(CONNECT_ACCOUNT_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_agni_sakshi'),
        },
    })
        .then((res) => {
            dispatch(connectBCAccountSuccess(res.data.result));
            cb(res.data.result);
        })
        .catch((error) => {
            dispatch(connectBCAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const verifyAccountInProgress = () => {
    return {
        type: VERIFY_ACCOUNT_IN_PROGRESS,
    };
};

const verifyAccountSuccess = (message, value) => {
    return {
        type: VERIFY_ACCOUNT_SUCCESS,
        message,
        value,
    };
};

const verifyAccountError = (message) => {
    return {
        type: VERIFY_ACCOUNT_ERROR,
        message,
        variant: 'error',
    };
};

export const verifyAccount = (userId, data, cb) => (dispatch) => {
    dispatch(verifyAccountInProgress());

    const url = urlVerifyAccount(userId);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_agni_sakshi'),
        },
    })
        .then((res) => {
            dispatch(verifyAccountSuccess('Success', res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(verifyAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message &&
                error.response.data.message.message
                    ? error.response.data.message.message
                    : error.response.data.message
                        ? error.response.data.message
                        : 'Failed!',
            ));
            cb(error);
        });
};

const fetchAccessTokenInProgress = () => {
    return {
        type: ACCESS_TOKEN_FETCH_IN_PROGRESS,
    };
};

const fetchAccessTokenSuccess = (message, value) => {
    return {
        type: ACCESS_TOKEN_FETCH_SUCCESS,
        message,
        value,
    };
};

const fetchAccessTokenError = (message) => {
    return {
        type: ACCESS_TOKEN_FETCH_ERROR,
        message,
    };
};

export const fetchAccessToken = (cb) => (dispatch) => {
    dispatch(fetchAccessTokenInProgress());

    const data = {
        refreshToken: localStorage.getItem('rfToken_agni_sakshi'),
    };

    Axios.post(ACCESS_TOKEN_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            localStorage.setItem('acToken_agni_sakshi', res.data && res.data.result['access_token']);
            localStorage.setItem('rfToken_agni_sakshi', res.data && res.data.result['refresh_token']);
            dispatch(fetchAccessTokenSuccess('Success', res.data && res.data.result['access_token']));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchAccessTokenError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const fetchProfileDetailsInProgress = () => {
    return {
        type: PROFILE_DETAILS_FETCH_IN_PROGRESS,
    };
};

const fetchProfileDetailsSuccess = (value) => {
    return {
        type: PROFILE_DETAILS_FETCH_SUCCESS,
        value,
    };
};

const fetchProfileDetailsError = (message) => {
    return {
        type: PROFILE_DETAILS_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchProfileDetails = (cb) => (dispatch) => {
    dispatch(fetchProfileDetailsInProgress());

    Axios.get(PROFILE_DETAILS_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_agni_sakshi'),
        },
    })
        .then((res) => {
            dispatch(fetchProfileDetailsSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchProfileDetailsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchFeeGrantInProgress = () => {
    return {
        type: FEE_GRANT_FETCH_IN_PROGRESS,
    };
};

const fetchFeeGrantSuccess = (value, message) => {
    return {
        type: FEE_GRANT_FETCH_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const fetchFeeGrantError = (message) => {
    return {
        type: FEE_GRANT_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchFeeGrant = (cb) => (dispatch) => {
    dispatch(fetchFeeGrantInProgress());

    Axios.get(FEE_GRANT_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_agni_sakshi'),
        },
    })
        .then((res) => {
            dispatch(fetchFeeGrantSuccess(res.data, 'Success'));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(fetchFeeGrantError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};
