import { combineReducers } from 'redux';
import {
    ALLOWANCES_FETCH_ERROR,
    ALLOWANCES_FETCH_IN_PROGRESS,
    ALLOWANCES_FETCH_SUCCESS,
    AUTHZ_ACCOUNT_FETCH_ERROR,
    AUTHZ_ACCOUNT_FETCH_IN_PROGRESS,
    AUTHZ_ACCOUNT_FETCH_SUCCESS,
    BALANCE_FETCH_ERROR,
    BALANCE_FETCH_IN_PROGRESS,
    BALANCE_FETCH_SUCCESS,
    FEE_GRANT_ALLOWANCE_ERROR,
    FEE_GRANT_ALLOWANCE_IN_PROGRESS,
    FEE_GRANT_ALLOWANCE_SUCCESS,
    GRANT_INFO_FETCH_ERROR,
    GRANT_INFO_FETCH_IN_PROGRESS,
    GRANT_INFO_FETCH_SUCCESS,
    TX_HASH_FETCH_IN_PROGRESS,
    TX_HASH_FETCH_SUCCESS,
    TX_HASH_IN_PROGRESS_FALSE_SET,
    TX_SIGN_AND_BROAD_CAST_SUCCESS,
} from '../../constants/wallet';

const balance = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case BALANCE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case BALANCE_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case BALANCE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const txHash = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case TX_HASH_FETCH_IN_PROGRESS:
    case TX_SIGN_AND_BROAD_CAST_SUCCESS:
        return {
            ...state,
            inProgress: true,
        };
    case TX_HASH_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TX_HASH_IN_PROGRESS_FALSE_SET:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const allowances = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case ALLOWANCES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ALLOWANCES_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ALLOWANCES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const feeGrant = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FEE_GRANT_ALLOWANCE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FEE_GRANT_ALLOWANCE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FEE_GRANT_ALLOWANCE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const grantInfo = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case GRANT_INFO_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case GRANT_INFO_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case GRANT_INFO_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const authzAccount = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case AUTHZ_ACCOUNT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case AUTHZ_ACCOUNT_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case AUTHZ_ACCOUNT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    balance,
    txHash,
    allowances,
    feeGrant,
    grantInfo,
    authzAccount,
});
