import { combineReducers } from 'redux';
import {
    CONNECT_WALLET_DIALOG_HIDE,
    CONNECT_WALLET_DIALOG_SHOW,
    LOGIN_OTP_ERROR,
    LOGIN_OTP_IN_PROGRESS,
    LOGIN_OTP_SUCCESS,
    LOGIN_USER_ERROR,
    LOGIN_USER_IN_PROGRESS,
    LOGIN_USER_SUCCESS,
    NAVBAR_TAB_VALUE_SET,
    PRIVATE_KEY_DIALOG_HIDE,
    PRIVATE_KEY_DIALOG_SHOW,
    PROCESSING_DIALOG_HIDE,
    PROCESSING_DIALOG_SHOW,
    WEB3AUTH_ID_VALUE_SET,
} from '../constants/navBar';

const navTab = (state = {
    value: 'home',
}, action) => {
    switch (action.type) {
    case NAVBAR_TAB_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const connectWalletDialog = (state = {
    value: {},
    open: false,
}, action) => {
    switch (action.type) {
    case CONNECT_WALLET_DIALOG_SHOW:
        return {
            ...state,
            value: action.value,
            open: true,
        };
    case CONNECT_WALLET_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const web3AuthID = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case WEB3AUTH_ID_VALUE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case CONNECT_WALLET_DIALOG_HIDE:
        return {
            ...state,
            value: '',
            valid: true,
        };
    default:
        return state;
    }
};

const privateKeyDialog = (state = {
    value: {},
    open: false,
}, action) => {
    switch (action.type) {
    case PRIVATE_KEY_DIALOG_SHOW:
        return {
            ...state,
            value: action.value,
            open: true,
        };
    case PRIVATE_KEY_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const processingDialog = (state = {
    value: {},
    open: false,
    step: 1,
    success: false,
}, action) => {
    switch (action.type) {
    case PROCESSING_DIALOG_SHOW:
        return {
            ...state,
            value: action.value,
            step: action.step,
            open: true,
            success: action.success || false,
        };
    case PROCESSING_DIALOG_HIDE:
        return {
            ...state,
            value: {},
            open: false,
            success: false,
        };
    default:
        return state;
    }
};

const loginUser = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case LOGIN_USER_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LOGIN_USER_SUCCESS:
        return {
            ...state,
            value: action.result,
            inProgress: false,
        };
    case LOGIN_USER_ERROR:
        return {
            ...state,
            value: {},
            inProgress: false,
        };

    default:
        return state;
    }
};

const loginOTP = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case LOGIN_OTP_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LOGIN_OTP_SUCCESS:
        return {
            ...state,
            value: action.result,
            inProgress: false,
        };
    case LOGIN_OTP_ERROR:
        return {
            ...state,
            value: {},
            inProgress: false,
        };

    default:
        return state;
    }
};

export default combineReducers({
    navTab,
    connectWalletDialog,
    web3AuthID,
    privateKeyDialog,
    processingDialog,
    loginUser,
    loginOTP,
});
