import { combineReducers } from 'redux';
import language from './language';
import snackbar from './snackbar';
import account from './account';
import interactiveVideo from './interactiveVideo';
import navBar from './navBar';
import leaderboard from './leaderboard';
import profile from './profile';
import cast from './cast';
import trailers from './trailers';

export default combineReducers({
    account,
    language,
    snackbar,
    interactiveVideo,
    navBar,
    leaderboard,
    profile,
    cast,
    trailers,
});
