import { combineReducers } from 'redux';
import BCDetails from './BCDetails';
import wallet from './wallet';
import web3Auth from './web3Auth';
import {
    ACCESS_TOKEN_FETCH_ERROR,
    ACCESS_TOKEN_FETCH_IN_PROGRESS,
    ACCESS_TOKEN_FETCH_SUCCESS,
    CONNECT_BC_ACCOUNT_ERROR,
    CONNECT_BC_ACCOUNT_IN_PROGRESS,
    CONNECT_BC_ACCOUNT_SUCCESS,
    EMAIL_ADDRESS_SET,
    PROFILE_DETAILS_FETCH_ERROR,
    PROFILE_DETAILS_FETCH_IN_PROGRESS,
    PROFILE_DETAILS_FETCH_SUCCESS,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_IN_PROGRESS,
    VERIFY_ACCOUNT_SUCCESS,
    FEE_GRANT_FETCH_SUCCESS,
    FEE_GRANT_FETCH_IN_PROGRESS,
    FEE_GRANT_FETCH_ERROR,
} from '../../constants/account';
import {
    CONNECT_KEPLR_ACCOUNT_ERROR,
    CONNECT_KEPLR_ACCOUNT_IN_PROGRESS,
    CONNECT_KEPLR_ACCOUNT_SUCCESS,
    CONNECT_LEAP_ACCOUNT_ERROR,
    CONNECT_LEAP_ACCOUNT_IN_PROGRESS,
    CONNECT_LEAP_ACCOUNT_SUCCESS,
    DISCONNECT_SET,
    KEPLR_ACCOUNT_KEYS_SET,
} from '../../constants/wallet';
import {
    ACCOUNT_DATA_FETCH_ERROR,
    ACCOUNT_DATA_FETCH_IN_PROGRESS,
    ACCOUNT_DATA_FETCH_SUCCESS,
} from '../../constants/web3Auth';
import { ADDRESS_SET } from '../../constants/cosmjsWallet';
import cosmjsWallet from './cosmjsWallet';

const email = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case EMAIL_ADDRESS_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    default:
        return state;
    }
};

const connection = (state = {
    inProgress: false,
    address: '',
    addressInProgress: true,
    keys: {},
}, action) => {
    switch (action.type) {
    case CONNECT_KEPLR_ACCOUNT_IN_PROGRESS:
    case CONNECT_LEAP_ACCOUNT_IN_PROGRESS:
    case ACCOUNT_DATA_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            addressInProgress: true,
        };
    case CONNECT_BC_ACCOUNT_IN_PROGRESS:
    case VERIFY_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CONNECT_KEPLR_ACCOUNT_SUCCESS:
    case CONNECT_LEAP_ACCOUNT_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.length &&
                action.value[0] && action.value[0].address,
        };
    case ADDRESS_SET:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value,
        };
    case ACCOUNT_DATA_FETCH_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.address,
        };
    case CONNECT_KEPLR_ACCOUNT_ERROR:
    case CONNECT_LEAP_ACCOUNT_ERROR:
    case ACCOUNT_DATA_FETCH_ERROR:
        // case AMINO_SIGN_SUCCESS:
        return {
            ...state,
            inProgress: false,
            addressInProgress: false,
        };
    case CONNECT_BC_ACCOUNT_ERROR:
    case CONNECT_BC_ACCOUNT_SUCCESS:
    case VERIFY_ACCOUNT_ERROR:
    case VERIFY_ACCOUNT_SUCCESS:
        return {
            ...state,
            inProgress: false,
        };
    case KEPLR_ACCOUNT_KEYS_SET:
        return {
            ...state,
            keys: action.value,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            inProgress: false,
            address: '',
            addressInProgress: true,
            keys: {},
        };
    default:
        return state;
    }
};

const token = (state = {
    inProgress: false,
    value: localStorage.getItem('acToken_agni_sakshi'),
}, action) => {
    switch (action.type) {
    case ACCESS_TOKEN_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACCESS_TOKEN_FETCH_SUCCESS:
    case VERIFY_ACCOUNT_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ACCESS_TOKEN_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const profileDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PROFILE_DETAILS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROFILE_DETAILS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PROFILE_DETAILS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const userFeeGrant = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FEE_GRANT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FEE_GRANT_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FEE_GRANT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    email,
    connection,
    token,
    profileDetails,
    userFeeGrant,
    wallet,
    bc: BCDetails,
    web3Auth,
    cosmjsWallet,
});
