export const NAVBAR_TAB_VALUE_SET = 'NAVBAR_TAB_VALUE_SET';

export const CONNECT_WALLET_DIALOG_SHOW = 'CONNECT_WALLET_DIALOG_SHOW';
export const CONNECT_WALLET_DIALOG_HIDE = 'CONNECT_WALLET_DIALOG_HIDE';
export const WEB3AUTH_ID_VALUE_SET = 'WEB3AUTH_ID_VALUE_SET';

export const PRIVATE_KEY_DIALOG_SHOW = 'PRIVATE_KEY_DIALOG_SHOW';
export const PRIVATE_KEY_DIALOG_HIDE = 'PRIVATE_KEY_DIALOG_HIDE';

export const PROCESSING_DIALOG_SHOW = 'PROCESSING_DIALOG_SHOW';
export const PROCESSING_DIALOG_HIDE = 'PROCESSING_DIALOG_HIDE';

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_IN_PROGRESS = 'LOGIN_USER_IN_PROGRESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_OTP_SUCCESS = 'LOGIN_OTP_SUCCESS';
export const LOGIN_OTP_IN_PROGRESS = 'LOGIN_OTP_IN_PROGRESS';
export const LOGIN_OTP_ERROR = 'LOGIN_OTP_ERROR';
