import React from 'react';
import * as PropTypes from 'prop-types';
import variables from '../../../utils/variables';
import { connect } from 'react-redux';
import './index.css';
import { Dialog } from '@mui/material';
import { hideConnectWalletDialog } from '../../../actions/navBar';
import KeplrButton from './KeplrButton';
import LeapConnectButton from './LeapConnectButton';
import Web3AuthButton from './Web3AuthButton';
import Banner from '../../../assets/dummy1.png';
import InfoIcon from '../../../assets/navBar/info.png';

const ConnectDialog = (props) => {
    return (
        <Dialog
            aria-describedby="connect with Agni Sakshi"
            aria-labelledby="Connect account"
            className="dialog connect_dialog"
            open={props.open}
            onClose={props.handleClose}>
            <div className="header_section">
                <img alt="banner" src={Banner}/>
            </div>
            <div className="connect_dialog_content">
                <h2 className="title">
                    {variables[props.lang]['join_the']}{' '}
                    <span>
                        {variables[props.lang]['agni_sakshi']}
                    </span>
                    {' '}{variables[props.lang].club}
                </h2>
                <div className="wallets_section">
                    <Web3AuthButton/>
                    <div className="sub_text">
                        <p>{variables[props.lang]['setup_using_wallet']}</p>
                        <img alt="info" src={InfoIcon}/>
                    </div>
                    <div className="crypto_wallets">
                        <LeapConnectButton/>
                        <KeplrButton/>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

ConnectDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.navBar.connectWalletDialog.open,
    };
};

const actionToProps = {
    handleClose: hideConnectWalletDialog,
};

export default connect(stateToProps, actionToProps)(ConnectDialog);
