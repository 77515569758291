export const PLAYER_SET = 'PLAYER_SET';
export const ACTIVE_INTERACTION_SET = 'ACTIVE_INTERACTION_SET';

export const DEVICE_ID_FETCH_IN_PROGRESS = 'DEVICE_ID_FETCH_IN_PROGRESS';
export const DEVICE_ID_FETCH_SUCCESS = 'DEVICE_ID_FETCH_SUCCESS';
export const DEVICE_ID_FETCH_ERROR = 'DEVICE_ID_FETCH_ERROR';

export const INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const INTERACTIVE_VIDEOS_FETCH_ERROR = 'INTERACTIVE_VIDEOS_FETCH_ERROR';

export const INTERACTIVE_VIDEO_FETCH_IN_PROGRESS = 'INTERACTIVE_VIDEO_FETCH_IN_PROGRESS';
export const INTERACTIVE_VIDEO_FETCH_SUCCESS = 'INTERACTIVE_VIDEO_FETCH_SUCCESS';
export const INTERACTIVE_VIDEO_FETCH_ERROR = 'INTERACTIVE_VIDEO_FETCH_ERROR';

export const INTERACTIVE_VIDEO_SESSION_ADD_IN_PROGRESS = 'INTERACTIVE_VIDEO_SESSION_ADD_IN_PROGRESS';
export const INTERACTIVE_VIDEO_SESSION_ADD_SUCCESS = 'INTERACTIVE_VIDEO_SESSION_ADD_SUCCESS';
export const INTERACTIVE_VIDEO_SESSION_ADD_ERROR = 'INTERACTIVE_VIDEO_SESSION_ADD_ERROR';

export const INTERACTIVE_VIDEO_ANSWER_SAVE_IN_PROGRESS = 'INTERACTIVE_VIDEO_ANSWER_SAVE_IN_PROGRESS';
export const INTERACTIVE_VIDEO_ANSWER_SAVE_SUCCESS = 'INTERACTIVE_VIDEO_ANSWER_SAVE_SUCCESS';
export const INTERACTIVE_VIDEO_ANSWER_SAVE_ERROR = 'INTERACTIVE_VIDEO_ANSWER_SAVE_ERROR';

export const INTERACTIVE_VIDEO_SESSION_COMPLETE_IN_PROGRESS = 'INTERACTIVE_VIDEO_SESSION_COMPLETE_IN_PROGRESS';
export const INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS = 'INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS';
export const INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR = 'INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR';

export const BADGE_CLAIM_FETCH_IN_PROGRESS = 'BADGE_CLAIM_FETCH_IN_PROGRESS';
export const BADGE_CLAIM_FETCH_SUCCESS = 'BADGE_CLAIM_FETCH_SUCCESS';
export const BADGE_CLAIM_FETCH_ERROR = 'BADGE_CLAIM_FETCH_ERROR';

export const CLEAR_DATA = 'CLEAR_DATA';
export const INTERACTIVE_SAVE_SET = 'INTERACTIVE_SAVE_SET';

export const IV_PARTICIPATED_DETAILS_FETCH_IN_PROGRESS = 'IV_PARTICIPATED_DETAILS_FETCH_IN_PROGRESS';
export const IV_PARTICIPATED_DETAILS_FETCH_SUCCESS = 'IV_PARTICIPATED_DETAILS_FETCH_SUCCESS';
export const IV_PARTICIPATED_DETAILS_FETCH_ERROR = 'IV_PARTICIPATED_DETAILS_FETCH_ERROR';

export const FETCH_ADVERTISEMENTS_IN_PROGRESS = 'FETCH_ADVERTISEMENT_IN_PROGRESS';
export const FETCH_ADVERTISEMENTS_SUCCESS = 'FETCH_ADVERTISEMENT_SUCCESS';
export const FETCH_ADVERTISEMENTS_ERROR = 'FETCH_ADVERTISEMENTS_ERROR';

export const IV_ALL_PARTICIPANTS_DETAILS_FETCH_IN_PROGRESS = 'IV_ALL_PARTICIPANTS_DETAILS_FETCH_IN_PROGRESS';
export const IV_ALL_PARTICIPANTS_DETAILS_FETCH_SUCCESS = 'IV_ALL_PARTICIPANTS_DETAILS_FETCH_SUCCESS';
export const IV_ALL_PARTICIPANTS_DETAILS_FETCH_ERROR = 'IV_ALL_PARTICIPANTS_DETAILS_FETCH_ERROR';
