const variables = {
    en: {
        submit: 'Submit',
        send_otp: 'Send OTP',
        skip: 'Skip',
        search: 'Search',
        streak: 'Streak',

        // wallet
        connect_keplr: 'Connect with Keplr',
        connect_with_email: 'Connect with Email',
        leap: 'Leap',
        connected_with_keplr: 'Connected with Keplr',
        disconnect: 'Disconnect',
        connect: 'Connect',
        confirm_membership: 'Confirm Membership',
        connecting: 'Connecting',
        connect_wallet: 'Connect Your Wallet',
        join_cb: 'Join the Agni Sakshi club',
        join_the: 'Join the',
        club: 'club',
        setup_using_wallet: 'Setup using wallet',
        keplr_wallet: 'Keplr Wallet',
        create_key_pair: 'Creating public and private key pair...',
        your_bc_address: 'Your OmniFlix blockchain account address',
        okay: 'okay',

        verify_email: 'VERIFY EMAIL',
        enter_id: 'Enter ID',
        verify_registration: 'This will verify your registration',
        verify: 'Verify',
        cancel: 'Cancel',
        invalid_attendee: 'Invalid Attendee ID',
        not_registered: 'Above ID is not registered. Enter valid ID',
        email_address: 'Email Address',
        account_details: 'Account Details',
        disconnected_successfully: 'Disconnected successfully',
        copy_mnemonic_continue: 'Copy your wallet Mnemonic and continue',
        create_or_import_wallet: 'Create or Import your existing wallet',
        enter_mnemonic: 'Enter Mnemonic',
        export_button: 'Export Mnemonic',
        download_file: 'Download File',
        read_and_agreed: 'I have read and agreed to the ',
        terms_and_conditions: 'terms and conditions',
        make_sure_you_have_downloaded_the_file: 'Make sure you have downloaded the File',
        make_sure_you_have_agreed_t_and_c: 'Make sure you have read and agreed the terms and conditions',
        make_sure_you_check_the_button_and_downloaded: 'Make sure you have check the terms and downloaded the file',

        // Alert messages
        fee_grant_request: 'Fee grant received successfully',
        choose_your_answer: 'Choose your answer',

        // Private key
        private_key: 'Private key',
        use_private_key: 'Use this private key across all OmniFlix products',
        copy: 'Copy',
        use_your_account: 'To use your account across other web3 apps',
        export_private_key: 'Export Private Key',

        // Home
        main_heading: 'Win a chance to meet Gowri & Shankar',
        win_a_chance_to_meet: 'Win a chance to meet',
        agni_sakshi: 'Agni Sakshi',
        gowri_shankar: 'Gowri & Shankar',
        join_the_club: 'Join the club',
        powered_by: 'Powered by',
        participate: 'Participate',
        participants: 'Participants',
        participate_now: 'Participate now',
        participated: 'Already Participated',
        watch_to_get_badge: 'Watch video and get a badge',
        ends_in: 'Ends in',
        starts_in: 'Starts in',
        completed: 'Completed',
        club_info: 'Club Info',
        house_mates_section: 'House Mates',
        home: 'Home',
        about: 'About',
        profile: 'Profile',
        sharable_link: 'Sharable link',
        user_name: 'User Name',
        since: 'since',
        days: 'days',
        name: 'Name',
        version: 'Agni Sakshi Version 1.0',
        portal_instructions: 'Portal Instructions',
        view_all: 'View all',
        win_chance_to_meet: 'Win a chance to meet Nagarjuna!',
        wallet_details: 'Wallet details',
        wallet_content: 'Download your wallet details with a click for easy setup of your OmniFlix.Market account later.',
        enter_last_4_digits: 'Please enter last 4 digits of your mobile number',
        claimed_badge: 'Claimed Participation Badge 🎉',
        view_badges: 'View badges',

        my_score: 'My score',
        leaderboard: 'leaderboard',
        board: 'Board',
        cast: 'Cast',
        crew: 'Crew',
        house_mates: 'Housemates',
        verify_mobile: 'Verify your mobile number',
        select_location: 'Select your location',
        enter_mobile_number: 'Enter your mobile number',
        enter_mobile_otp: 'Enter OTP sent your mobile number ',
        account_verified_success: 'Account verified successfully',
        you_won_badge: 'You won a badge 🎉',
        total_badges: 'Total badges',
        terms_conditions: 'Terms and condition',
        select_preferred_lang: 'Select Preferred language',
        select_your_preferred_lang: 'Select your preferred language',
        refer_earn_points: 'Refer and earn points',
        logout: 'Logout',
        updates: 'Updates',
        back: 'Back',
        term1: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',

        // Updates Page
        updates_h1: 'Lorem Ipsum - Lorem Ipsum is simply text',
        updates_p1: 'The latest update for Agni Sakshi club',
        updates_p2: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
        updates_ul: 'Lorem Ipsum is simply dummy text',
        updates_h2: 'Lorem Ipsum is simply text',

        // Interactions
        no_active_sessions: 'No Active session found, please refresh the screen',
        session_does_not_exists: 'Session does not exists',
        did_not_got_answer: 'Did not got the answer value',

        //    club info
        trailers: 'Trailers',
        meet_cast: 'Meet the cast',
        meet_crew: 'Meet the crew',
        cb_content1: 'The Portal is a special place where you engage-to-earn badges top the leaderboard to meet Agni Sakshi',
        follow_steps: 'Follow the steps listed 👇 to participate.',
        step: 'Step',
        scan_qr_code: 'Scan QR code',
        watch_iv: 'Watch the interactive video',
        earn_badge: 'Earn the badge',
        top_leaderboard: 'Top the Leaderboard',
        meet_nag: 'Meet Nagarjuna',
        bb_header2: 'Lorem Ipsum',
        bb_content2: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        bb_content3: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        about_contest: 'About contest',
        meet_agni_sakshi: 'Meet Agni Sakshi',
        meet_gowri_shankar: 'Meet Gowri and Shankar',
        explore_about_contest: 'Explore about contest',
        explore_more: 'Explore more',

        // Trailers
        trailers_and_more: 'Trailers and more',
        already_participated: "You've already participated",
        wait_till_tomorrow: 'Wait until tomorrow to participate again',
        badge_unclaimed: 'Badge remains unclaimed',
        claim_badge_to_get_benefits: 'Claim the badge to get benefits',

        layer_zero_labs: '© 2024 LayerZeroXTech Labs',
        watch_published_content: 'Watch published content',
        published_content: 'Published content',
        people_participated: 'people participated in this Interactive Video',
        first_to_participate: 'Become the first to participate in this Interactive Video',

        gratification: 'Gratification',
        the: 'The',
        top_25: 'top 25 participants',
        opportunity_for_a_meet: 'have the opportunity for a meet and greet with the Agni Sakshi team.',
        more_surprises: 'More surprises await all participants!',
        keep_an_eye: 'Keep an eye on this page',
        position: 'Position',
        badges: 'Badges',
        points: 'Points',
        how_users_performing: 'See how other users are performing',
        explore_how: 'Explore how?',
        account_setup: 'Setup your account',
        upload_profile_picture: 'Upload profile picture',
    },
    te: {
        submit: 'సమర్పించు',
        skip: 'వదలండి',
        search: 'శోధించు',
        streak: 'పరంపర',

        // wallet
        connect_keplr: 'కేప్ల్రుతో కనెక్ట్ చేయండి',
        connect_with_email: 'ఇమెయిల్ తో కనెక్ట్ చేయండి',
        leap: 'లీప్',
        connected_with_keplr: 'కేప్ల్రుతో కనెక్ట్ అయ్యారు',
        disconnect: 'డిస్కనెక్ట్',
        connect: 'కనెక్ట్',
        confirm_membership: 'సభ్యత్వాన్ని నిర్ధారించండి',
        connecting: 'కనెక్ట్ అవుతోంది',
        connect_wallet: 'మీ వాలెట్‌ను కనెక్ట్ చేయండి',
        setup_using_wallet: 'వాలెట్ ఉపయోగించి సెట్అప్ చేయండి',
        keplr_wallet: 'కేప్ల్రు వాలెట్',
        create_key_pair: 'పబ్లిక్ మరియు ప్రైవేట్ కీ పేర్లు సృష్టించడం...',
        your_bc_address: 'మీ ఆమ్నిఫ్లిక్స్ బ్లాక్‌చేన్ ఖాతా చిరునామా',
        okay: 'సరే',

        verify_email: 'ఇమెయిల్ ధృవీకరించండి',
        enter_id: 'ఐడి నమోదు చేయండి',
        verify_registration: 'ఈటివర్కే మీ నమోదుని ధృవీకరించడం వలన ఉంది',
        verify: 'ధృవీకరించు',
        cancel: 'రద్దు',
        invalid_attendee: 'చెల్లని అంశంపు ఐడి',
        not_registered: 'పైన పేరును నమోదు చేయబడలేదు. చెల్లని ఐడిని నమోదు చేయండి',
        email_address: 'ఇమెయిల్ చిరునామా',
        account_details: 'ఖాతా వివరాలు',
        disconnected_successfully: 'యశస్విగా డిస్కనెక్ట్ చేయబడింది',
        copy_mnemonic_continue: 'మీ వాలెట్ మ్యానమిక్ ను కాపీ చేసి కొనియించండి మరియు కొనియించడానికి కొనిగా ఉండండి',
        create_or_import_wallet: 'మీ వాలెట్‌ను సృష్టించండి లేదా దానిని దిగుమతి వాలెట్‌గా ఎంచుకోండి',
        enter_mnemonic: 'మ్యానమిక్‌ను ఎంటర్ చేయండి',
        export_button: 'మ్యానమిక్ ఎగుమతించు',
        download_file: 'ఫైల్‌ని డౌన్‌లోడ్ చేయండి',
        read_and_agreed: 'నేను చదివి అంగీకరించాను ',
        terms_and_conditions: 'నిబంధనలు మరియు షరతులు',
        make_sure_you_have_downloaded_the_file: 'మీరు ఫైల్‌ని డౌన్‌లోడ్ చేశారని నిర్ధారించుకోండి',
        make_sure_you_have_agreed_t_and_c: 'మీరు నిబంధనలు మరియు షరతులను చదివి, అంగీకరించారని నిర్ధారించుకోండి',
        make_sure_you_check_the_button_and_downloaded: 'మీరు నిబంధనలను తనిఖీ చేసి, ఫైల్‌ను డౌన్‌లోడ్ చేశారని నిర్ధారించుకోండి',

        // Alert messages
        fee_grant_request: 'ఫీ అనుమతి విజయవంతమైంది',
        choose_your_answer: 'మీ సమాధానాన్ని ఎంచుకోండి',

        // Private key
        private_key: 'ప్రైవేట్ కీ',
        use_private_key: 'ఈ ప్రైవేట్ కీని అన్ని ఆమ్నిఫ్లిక్స్ ఉత్పన్నాలలో ఉపయోగించండి',
        copy: 'కాపీ',
        use_your_account: 'మీ ఖాతాను ఉపయోగించండి',
        export_private_key: 'ప్రైవేట్ కీ ఎగుమతి చేయండి',

        // Home
        main_heading: 'నాగార్జునతో భేటి చేయడానికి ఒక అవకాశం గెలవండి!',
        join_the_club: 'క్లబ్‌లో చేరండి',
        powered_by: 'ద్వారం శక్తిపొందండి',
        participate_now: 'ఇప్పుడు పాలుపంచుకోండి',
        participated: 'ఇప్పటికే పాల్గొన్నారు',
        ends_in: 'లో ముగిస్తుంది',
        starts_in: 'లో ప్రారంభమవుతుంది',
        completed: 'పూర్తయింది',
        club_info: 'క్లబ్ సమాచారం',
        house_mates_section: 'హౌస్ మేట్స్ విభాగం',
        home: 'హోమ్',
        about: 'గురించి',
        profile: 'ప్రొఫైల్',
        sharable_link: 'షేరబుల్ లింక్',
        user_name: 'వినియోగదారు',
        since: 'నుండి',
        days: 'రోజులు',
        name: 'పేరు',
        version: 'Agni Sakshi Version 1.0',
        view_all: 'అన్ని చూడండి',
        win_chance_to_meet: 'నాగార్జునని కలిసే అవకాశం గెలవండి!',
        wallet_details: 'వాలెట్ వివరాలు',
        wallet_content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        enter_last_4_digits: 'దయచేసి మీ మొబైల్ నంబర్‌లోని చివరి 4 అంకెలను నమోదు చేయండి',

        my_score: 'నా స్కోరు',
        leaderboard: 'లీడర్బోర్డ్',
        house_mates: 'హౌస్‌మేట్స్',
        verify_mobile: 'మీ మొబైల్ నంబర్‌ను ధృవీకరించండి',
        select_location: 'మీ స్థానం ఎంచుకోండి',
        enter_mobile_number: 'మీ మొబైల్ నంబర్‌ను నమోదు చేయండి',
        enter_mobile_otp: 'మీ మొబైల్ నంబర్‌కి పంపిన OTP ను నమోదు చేయండి',
        account_verified_success: 'ఖాతా వివరణ విజయవంతంగా ధృవీకరించబడింది',
        you_won_badge: 'మీరు ఒక బ్యాడ్జ్ గెలిచారు 🎉',
        total_badges: 'మొత్తం బ్యాడ్జ్‌లు',
        terms_conditions: 'నిబంధనలు మరియు షరతులు',
        select_preferred_lang: 'ప్రాధాన్యత భాషను ఎంచుకోండి',
        select_your_preferred_lang: 'మీ ప్రాధాన్యత భాషను ఎంచుకోండి',
        refer_earn_points: 'రిఫర్ చేసి పాయింట్లు సంపాదించండి',
        logout: 'లాగ్అవుట్',
        updates: 'అప్డేట్‌లు',
        back: 'తిరిగి',
        term1: 'లోరెమ్ ఇప్సమ్ అని సింపుల్యు డమ్మీ టెక్స్ట్ ది ప్రింటింగ్ అండ్ టైప్‌సెట్టింగ్ ఇండస్ట్రీలో ఉంది. లోరెమ్ ఇప్సమ్ అని ఒక అనగార్తం ముద్రణ మరియు టైప్‌సెట్‌టింగ్ పరిష్కరణ పరంగా అమర్చబడింది. లోరెమ్ ఇప్సమ్‌ను 1500ల సమయంలో, అపరిచిత ముద్రణకర్త ప్రకటన విధంగా వేసి, అదినాటికి ఒక పందెం టైప్ యాత్రా పుస్తకంగా అంచనా చేసుకుందాం.',

        // Updates Page
        updates_h1: 'Lorem Ipsum - Lorem Ipsum is simply text',
        updates_p1: 'The latest update for Agni Sakshi club',
        updates_p2: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
        updates_ul: 'Lorem Ipsum is simply dummy text',
        updates_h2: 'Lorem Ipsum is simply text',

        // Interactions
        no_active_sessions: 'ఎంపిక సమయం కనుగొనబడలేదు, దయచేసి స్క్రీన్‌ని రిఫ్రెష్ చేయండి',
        session_does_not_exists: 'సెషన్ లేదు',
        did_not_got_answer: 'సమాధాన విలువ రాలేదు',

        //    club info
        cb_content1: 'The Portal is a special place where you engage-to-earn badges top the leaderboard to meet Agni Sakshi',
        follow_steps: 'పాలుపంచడానికి క్రమాలు మరియు మీరు పాటులోని చూసుకోవాల్సిన క్రమాలను అనుసరించండి.',
        step: 'ప్రకారం',
        scan_qr_code: 'QR కోడ్‌ను స్క్యాన్ చేయండి',
        watch_iv: 'ఇంటరాక్టివ్ వీడియోను చూడండి',
        earn_badge: 'బాడ్జు సంపాదించండి',
        top_leaderboard: 'లీడర్‌బోర్డును ప్రాప్తించండి',
        meet_nag: 'నాగార్జునని కలవండి',
        bb_header2: 'లోరెం ఇప్సమ్',
        bb_content2: 'లోరెం ఇప్సమ్ మాత్రమే ప్రింటింగ్ మరియు టైప్‌సెట్టింగ్ వినియోగ శాస్త్రం లో డమ్మీ టెక్స్ట్ ఉంది. లోరెం ఇప్సమ్ విశిష్ట గరిష్ఠ సమయంలో, మరియు గుర్తు చేయని ముద్రణకర్త ఒక విధంగా రాశి యొక్క గ్యాలిలో తీసివేయబడింది.',
        bb_content3: 'లోరెం ఇప్సమ్ మాత్రమే ప్రింటింగ్ మరియు టైప్‌సెట్టింగ్ శాస్త్రం లో డమ్మీ టెక్స్ట్ ఉంది. లోరెం ఇప్సమ్ వారసత్యంగా 1500ల దశలో అజ్ఞాత ముద్రణకర్త ఒక విధంగా రాశి యొక్క గ్యాలిలో తీసివేయబడింది.',
    },
};

export default variables;
