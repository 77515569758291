export const CONNECT_WEB3AUTH_ACCOUNT_IN_PROGRESS = 'CONNECT_WEB3AUTH_ACCOUNT_IN_PROGRESS';
export const CONNECT_WEB3AUTH_ACCOUNT_SUCCESS = 'CONNECT_WEB3AUTH_ACCOUNT_SUCCESS';
export const CONNECT_WEB3AUTH_ACCOUNT_ERROR = 'CONNECT_WEB3AUTH_ACCOUNT_ERROR';

export const CONNECTING_ACCOUNT_SET = 'CONNECTING_ACCOUNT_SET';
export const DISCONNECT_ACCOUNT_SET = 'DISCONNECT_ACCOUNT_SET';
export const ACCOUNT_DATA_SET = 'ACCOUNT_DATA_SET';

export const ACCOUNT_DATA_FETCH_IN_PROGRESS = 'ACCOUNT_DATA_FETCH_IN_PROGRESS';
export const ACCOUNT_DATA_FETCH_SUCCESS = 'ACCOUNT_DATA_FETCH_SUCCESS';
export const ACCOUNT_DATA_FETCH_ERROR = 'ACCOUNT_DATA_FETCH_ERROR';

export const SIGN_TRANSACTION_IN_PROGRESS = 'SIGN_TRANSACTION_IN_PROGRESS';
export const SIGN_TRANSACTION_SUCCESS = 'SIGN_TRANSACTION_SUCCESS';
export const SIGN_TRANSACTION_ERROR = 'SIGN_TRANSACTION_ERROR';

export const SEQUENCE_FETCH_IN_PROGRESS = 'SEQUENCE_FETCH_IN_PROGRESS';
export const SEQUENCE_FETCH_SUCCESS = 'SEQUENCE_FETCH_SUCCESS';
export const SEQUENCE_FETCH_ERROR = 'SEQUENCE_FETCH_ERROR';

export const UPDATE_USER_SET = 'UPDATE_USER_SET';
