import { Button } from '@mui/material';
import { ReactComponent as HomeIcon } from '../../assets/navBar/home.svg';
import { ReactComponent as LeaderboardIcon } from '../../assets/navBar/leaderboard.svg';
import { ReactComponent as StarIcon } from '../../assets/navBar/star.svg';
import { ReactComponent as UserIcon } from '../../assets/navBar/user.svg';
import './index.css';
import * as PropTypes from 'prop-types';
import withRouter from '../../components/WithRouter';
import { connect } from 'react-redux';
import { setNavBarTab } from '../../actions/navBar';
import React, { useEffect } from 'react';
import PrivateKeyDialog from './PrivateKeyDialog';
import ProcessingDialog from './ProcessingDialog';
import variables from '../../utils/variables';

const NavBar = (props) => {
    const {
        router,
        setNavBarTab,
    } = props;

    useEffect(() => {
        const active = router.location && router.location.pathname && router.location.pathname.split('/');
        handleUseEffect(active);
    }, [router, setNavBarTab]);

    const handleUseEffect = (active) => {
        if (active && active.length && active[1]) {
            switch (active[1]) {
            case 'leaderboard':
            case 'board':
            case 'gratification':
                return setNavBarTab('board');
            case 'cast':
                return setNavBarTab('cast');
            case 'profile':
            case 'user':
                return setNavBarTab('profile');
            case 'about':
                return setNavBarTab('about');
            case 'interactiveVideo':
            default:
                return setNavBarTab('home');
            }
        } else {
            setNavBarTab('home');
        }
    };

    const handleTab = (value) => {
        props.setNavBarTab(value);
        if (value === 'home') {
            props.router.navigate('/');
        } else {
            props.router.navigate(value);
        }
    };

    return (
        <div className="nav_bar">
            <Button
                className={props.activeTab === 'home' ? 'active_tab' : ''}
                onClick={() => handleTab('home')}>
                <HomeIcon/>
                {variables[props.lang].home}
            </Button>
            <Button
                className={props.activeTab === 'board' ? 'active_tab' : ''}
                onClick={() => handleTab('board')}>
                <LeaderboardIcon/>
                {variables[props.lang].board}
            </Button>
            <Button
                className={props.activeTab === 'cast' ? 'active_tab' : ''}
                onClick={() => handleTab('cast')}>
                <StarIcon/>
                {variables[props.lang].cast}
            </Button>
            <Button
                className={props.activeTab === 'profile' ? 'active_tab' : ''}
                onClick={() => handleTab('profile')}>
                <UserIcon/>
                {variables[props.lang].profile}
            </Button>
            <PrivateKeyDialog/>
            <ProcessingDialog/>
        </div>
    );
};

NavBar.propTypes = {
    activeTab: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    setNavBarTab: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        activeTab: state.navBar.navTab.value,
        address: state.account.connection.address,
        lang: state.language,
        profileDetails: state.account.profileDetails.value,
    };
};

const actionToProps = {
    setNavBarTab,
};

export default withRouter(connect(stateToProps, actionToProps)(NavBar));
