import { combineReducers } from 'redux';
import {
    FETCH_LEADERBOARD_BADGES_ERROR,
    FETCH_LEADERBOARD_BADGES_IMAGE_SUCCESS,
    FETCH_LEADERBOARD_BADGES_IN_PROGRESS,
    FETCH_LEADERBOARD_BADGES_SUCCESS,
    FETCH_USER_BADGES_ERROR,
    FETCH_USER_BADGES_IN_PROGRESS,
    FETCH_USER_BADGES_SUCCESS,
    FETCH_USER_DETAILS_ERROR,
    FETCH_USER_DETAILS_IN_PROGRESS,
    FETCH_USER_DETAILS_SUCCESS,
    FETCH_USER_IMAGE_ERROR,
    FETCH_USER_IMAGE_IN_PROGRESS,
    FETCH_USER_IMAGE_SUCCESS, FETCH_USER_STREAK_ERROR, FETCH_USER_STREAK_IN_PROGRESS, FETCH_USER_STREAK_SUCCESS,
    SEARCH_VALUE_SET,
    USER_POSITION_FETCH_ERROR,
    USER_POSITION_FETCH_IN_PROGRESS,
    USER_POSITION_FETCH_SUCCESS,
    USER_POSITION_IMAGE_FETCH_SUCCESS,
} from '../constants/leaderboard';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../config';
import { DISCONNECT_SET } from '../constants/wallet';

const searchValue = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SEARCH_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const leaderboardBadges = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
    images: [],
}, action) => {
    switch (action.type) {
    case FETCH_LEADERBOARD_BADGES_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_LEADERBOARD_BADGES_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    case FETCH_LEADERBOARD_BADGES_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case FETCH_LEADERBOARD_BADGES_IMAGE_SUCCESS: {
        const array = [...state.images];
        if (action.value && array.indexOf(action.value) === -1) {
            array.push(action.value);
        }
        return {
            ...state,
            images: [...array],
        };
    }
    default:
        return state;
    }
};

const userPosition = (state = {
    inProgress: false,
    value: {},
    images: [],
}, action) => {
    switch (action.type) {
    case USER_POSITION_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case USER_POSITION_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case USER_POSITION_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case USER_POSITION_IMAGE_FETCH_SUCCESS: {
        const array = [...state.images];
        if (action.value && array.indexOf(action.value) === -1) {
            array.push(action.value);
        }
        return {
            ...state,
            images: [...array],
        };
    }
    default:
        return state;
    }
};

const userImage = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_USER_IMAGE_IN_PROGRESS:
        // case UPDATE_PROFILE_IMAGE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_IMAGE_SUCCESS:
        // case UPDATE_PROFILE_IMAGE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_USER_IMAGE_ERROR:
        // case UPDATE_PROFILE_IMAGE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const userDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_USER_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_DETAILS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_USER_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const userBadges = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_USER_BADGES_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_BADGES_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    case FETCH_USER_BADGES_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const userStreak = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case FETCH_USER_STREAK_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_STREAK_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case FETCH_USER_STREAK_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    searchValue,
    leaderboardBadges,
    userPosition,
    userImage,
    userDetails,
    userBadges,
    userStreak,
});
