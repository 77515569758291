import { LANGUAGE_VALUE_SET } from '../constants/language';

const language = (state = localStorage.getItem('language_agni_sakshi') || 'en', action) => {
    if (action.type === LANGUAGE_VALUE_SET) {
        return action.value;
    }

    return state;
};

export default language;
