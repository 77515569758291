import React, { useEffect } from 'react';
import agniSakshiLogo from '../../assets/topNavBar/agni-sakshi.png';
import * as PropTypes from 'prop-types';
import withRouter from '../../components/WithRouter';

const LeftSection = (props) => {
    useEffect(() => {
        if (localStorage.getItem('acToken_agni_sakshi') && !localStorage.getItem('agni_sakshi_address')) {
            props.router.navigate('/walletCreation');
        }
    }, []);

    const handleRedirect = () => {
        props.router.navigate('/');
    };

    return (
        <div className="left">
            <img alt="agnisakhi" src={agniSakshiLogo} onClick={handleRedirect}/>
        </div>
    );
};

LeftSection.propTypes = {
    profileDetails: PropTypes.object.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
};

export default withRouter(LeftSection);
