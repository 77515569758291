import {
    ACCOUNT_DATA_FETCH_ERROR,
    ACCOUNT_DATA_FETCH_IN_PROGRESS,
    ACCOUNT_DATA_FETCH_SUCCESS,
    ACCOUNT_DATA_SET,
    CONNECT_WEB3AUTH_ACCOUNT_ERROR,
    CONNECT_WEB3AUTH_ACCOUNT_IN_PROGRESS,
    CONNECT_WEB3AUTH_ACCOUNT_SUCCESS,
    CONNECTING_ACCOUNT_SET,
    DISCONNECT_ACCOUNT_SET,
    SEQUENCE_FETCH_ERROR,
    SEQUENCE_FETCH_IN_PROGRESS,
    SEQUENCE_FETCH_SUCCESS,
    SIGN_TRANSACTION_ERROR,
    SIGN_TRANSACTION_IN_PROGRESS,
    SIGN_TRANSACTION_SUCCESS,
    UPDATE_USER_SET,
} from '../../constants/web3Auth';
import { Web3Auth } from '@web3auth/web3auth';
import { ADAPTER_EVENTS, CHAIN_NAMESPACES, WALLET_ADAPTERS } from '@web3auth/base';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { DirectSecp256k1Wallet, encodePubkey, makeSignDoc } from '@cosmjs/proto-signing';
import { Secp256k1Wallet } from '@cosmjs/amino';
import { encodeSecp256k1Pubkey } from '@cosmjs/amino/build/encoding';
import { AuthInfo, TxBody, TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx';
import { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx';
import { fromBase64, toBase64 } from '@cosmjs/encoding';
import { config } from '../../config';
import Logo from '../../assets/wallets/chhotabheem.png';
import Axios from 'axios';
import { convertToCamelCase } from '../../utils/strings';
import { customTypes } from '../../registry';
import { setEmailAddress } from './index';
import { makeSignDoc as AminoMakeSignDoc } from '@cosmjs/amino/build/signdoc';

export const setUpdateUser = (address, data) => {
    return {
        type: UPDATE_USER_SET,
        address,
        data,
    };
};

const connectAccountInProgress = () => {
    return {
        type: CONNECT_WEB3AUTH_ACCOUNT_IN_PROGRESS,
    };
};

export const setConnectingAccount = (value) => {
    return {
        type: CONNECTING_ACCOUNT_SET,
        value,
    };
};

const connectAccountSuccess = (value) => {
    return {
        type: CONNECT_WEB3AUTH_ACCOUNT_SUCCESS,
        value,
    };
};

const connectAccountError = (message) => {
    return {
        type: CONNECT_WEB3AUTH_ACCOUNT_ERROR,
        message,
    };
};

const setDisconnectAccount = () => {
    return {
        type: DISCONNECT_ACCOUNT_SET,
    };
};

export const setAccountData = (value) => {
    return {
        type: ACCOUNT_DATA_SET,
        value,
    };
};

export const initializeWeb3AuthChain = (cb) => (dispatch) => {
    dispatch(connectAccountInProgress());
    const subscribeAuthEvents = (web3auth) => {
        web3auth.on(ADAPTER_EVENTS.CONNECTED, (data) => {
            dispatch(setConnectingAccount(false));
            if (cb) {
                cb(web3auth);
            }
            if (data && data.reconnected) {
                dispatch(fetchAccountData(web3auth));
            }
        });

        web3auth.on(ADAPTER_EVENTS.CONNECTING, (data) => {
            dispatch(setConnectingAccount(true));
            if (data && data.login_hint) {
                dispatch(setEmailAddress(data.login_hint));
            }
        });

        web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
            dispatch(setConnectingAccount(false));
            dispatch(setDisconnectAccount());
        });

        web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
            dispatch(setConnectingAccount(false));
            dispatch(connectAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : error.message
                        ? error.message
                        : 'Failed!'));
        });
    };

    (async () => {
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const web3AuthInstance = new Web3Auth({
            chainConfig: {
                chainNamespace: CHAIN_NAMESPACES.OTHER,
                chainId: config.CHAIN_ID,
                rpcTarget: config.RPC_URL,
            },
            uiConfig: {
                appLogo: Logo,
                theme: 'dark',
            },
            clientId,
            enableLogging: false,
        });
        const adapter = new OpenloginAdapter({
            adapterSettings: {
                network: 'testnet',
                clientId,
            },
        });

        web3AuthInstance.configureAdapter(adapter);
        subscribeAuthEvents(web3AuthInstance);
        dispatch(connectAccountSuccess(web3AuthInstance));
        if (!web3AuthInstance.cachedAdapter) {
            dispatch(setConnectingAccount(false));
        }
        await web3AuthInstance.initModal({
            modalConfig: {
                [WALLET_ADAPTERS.OPENLOGIN]: {
                    loginMethods: {
                        google: {
                            name: 'google login',
                            logoDark: 'url to your custom logo which will shown in dark mode',
                        },
                        github: {
                            showOnModal: false,
                        },
                        linkedin: {
                            showOnModal: false,
                        },
                        twitter: {
                            showOnModal: false,
                        },
                        facebook: {
                            showOnModal: false,
                        },
                        reddit: {
                            showOnModal: false,
                        },
                        discord: {
                            showOnModal: false,
                        },
                        twitch: {
                            showOnModal: false,
                        },
                        line: {
                            showOnModal: false,
                        },
                        kakao: {
                            showOnModal: false,
                        },
                        weibo: {
                            showOnModal: false,
                        },
                        wechat: {
                            showOnModal: false,
                        },
                        email_passwordless: {
                            showOnModal: false,
                        },
                        sms_passwordless: {
                            showOnModal: false,
                        },
                    },
                },
            },
        });
    })();
};

const fetchAccountDataInProgress = () => {
    return {
        type: ACCOUNT_DATA_FETCH_IN_PROGRESS,
    };
};

const fetchAccountDataSuccess = (value, pubkeyData, privKey, aminoPubkeyData) => {
    return {
        type: ACCOUNT_DATA_FETCH_SUCCESS,
        value,
        pubkeyData,
        privKey,
        aminoPubkeyData,
    };
};

const fetchAccountDataError = (message) => {
    return {
        type: ACCOUNT_DATA_FETCH_ERROR,
        message,
    };
};

export const fetchAccountData = (web3auth, cb) => (dispatch) => {
    dispatch(fetchAccountDataInProgress());

    (async () => {
        await web3auth.connect().then(async () => {
            const provider = web3auth.provider;
            if (!provider) {
                dispatch(fetchAccountDataError('provider is null'));

                return;
            }

            const privKey = await provider.request({ method: 'private_key' });

            const newKey = Buffer.from(privKey.toString(), 'hex');
            const pubkeyData = DirectSecp256k1Wallet.fromKey(newKey, config.PREFIX);
            const aminoPubkeyData = Secp256k1Wallet.fromKey(newKey, config.PREFIX);
            (await pubkeyData).getAccounts().then((r) => {
                if (r && r.length && r[0]) {
                    dispatch(fetchAccountDataSuccess(r[0], pubkeyData, privKey, aminoPubkeyData));
                }
                if (cb) {
                    cb(r[0], aminoPubkeyData);
                }
            }).catch((error) => {
                dispatch(fetchAccountDataError(
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                        ? error.response.data.message
                        : error.message
                            ? error.message
                            : 'Failed!'));
                if (cb) {
                    cb(null);
                }
            });
        }).catch((error) => {
            dispatch(fetchAccountDataError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : error.message
                        ? error.message
                        : 'Failed!'));
            if (cb) {
                cb(null);
            }
        });
    })();
};

const signTransactionInProgress = () => {
    return {
        type: SIGN_TRANSACTION_IN_PROGRESS,
    };
};

const signTransactionSuccess = (value) => {
    return {
        type: SIGN_TRANSACTION_SUCCESS,
        value,
    };
};

const signTransactionError = (message) => {
    return {
        type: SIGN_TRANSACTION_ERROR,
        message,
    };
};

export const signTransactionAmino = (pubkeyData, account, tx, cb) => (dispatch) => {
    dispatch(signTransactionInProgress());

    (async () => {
        try {
            const signDoc = AminoMakeSignDoc(
                tx.msgs ? tx.msgs : [tx.msg],
                tx.fee,
                config.CHAIN_ID,
                tx.memo,
                account.accountNumber,
                account.sequence,
            );

            (await pubkeyData).signAmino(account && account.address, signDoc).then((result) => {
                if (result && result.code !== undefined && result.code !== 0) {
                    dispatch(signTransactionError(result.log || result.rawLog));
                    cb(null);
                } else {
                    dispatch(signTransactionSuccess(result));
                    cb(result);
                }
            }).catch((error) => {
                dispatch(signTransactionError(error && error.message));
                cb(null);
            });
        } catch (e) {
            dispatch(signTransactionError(e && e.message));
            cb(null);
        }
    })();
};

export const signTransaction = (pubkeyData, account, tx, cb) => (dispatch) => {
    dispatch(signTransactionInProgress());

    (async () => {
        let pubkey = account && account.pubkey && encodeSecp256k1Pubkey(account.pubkey);
        pubkey = account && account.pubkey && pubkey && pubkey.value &&
            encodePubkey(pubkey);

        let authInfo = {
            signerInfos: [{
                publicKey: pubkey,
                modeInfo: {
                    single: {
                        mode: 1,
                    },
                },
                sequence: account && account.sequence,
            }],
            fee: { ...tx.fee },
        };
        authInfo = AuthInfo.encode(AuthInfo.fromPartial(authInfo)).finish();

        let msgValue = tx.msgs ? tx.msgs && tx.msgs[0] && tx.msgs[0].value : tx.msg && tx.msg.value;
        msgValue = msgValue && convertToCamelCase(msgValue);
        let typeUrl = tx.msgs ? tx.msgs && tx.msgs[0] && tx.msgs[0].typeUrl : tx.msg && tx.msg.typeUrl;

        if (tx.msgType) {
            const type = customTypes[tx.msgType].type;
            typeUrl = customTypes[tx.msgType].typeUrl;
            msgValue = type.encode(type.fromPartial(msgValue)).finish();
        } else if (typeUrl === '/ibc.applications.transfer.v1.MsgTransfer') {
            msgValue = MsgTransfer.encode(MsgTransfer.fromPartial(msgValue)).finish();
        }

        let bodyBytes = {
            messages: [{
                typeUrl: typeUrl,
                value: msgValue,
            }],
            memo: tx.memo,
        };
        bodyBytes = TxBody.encode(TxBody.fromPartial(bodyBytes)).finish();

        const signDoc = makeSignDoc(
            bodyBytes,
            authInfo,
            config.CHAIN_ID,
            account && account.accountNumber,
        );

        (await pubkeyData).signDirect(
            account && account.address,
            signDoc).then((result) => {
            const txRaw = TxRaw.fromPartial({
                bodyBytes: result.signed.bodyBytes,
                authInfoBytes: result.signed.authInfoBytes,
                signatures: [fromBase64(result.signature.signature)],
            });
            const txBytes = TxRaw.encode(txRaw).finish();
            if (result && result.code !== undefined && result.code !== 0) {
                dispatch(signTransactionSuccess(result.log || result.rawLog));
            } else {
                dispatch(signTransactionSuccess(result));
                cb(result, toBase64(txBytes));
            }
        }).catch((error) => {
            dispatch(signTransactionError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : error.message
                        ? error.message
                        : 'Failed!'));
            if (cb) {
                cb(null);
            }
        });
    })();
};

const fetchSequenceInProgress = () => {
    return {
        type: SEQUENCE_FETCH_IN_PROGRESS,
    };
};

const fetchSequenceSuccess = (value) => {
    return {
        type: SEQUENCE_FETCH_SUCCESS,
        value,
    };
};

const fetchSequenceError = (message) => {
    return {
        type: SEQUENCE_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchSequence = (address, cb) => (dispatch) => {
    dispatch(fetchSequenceInProgress());

    const url = config.REST_URL + '/auth/accounts/' + address;
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchSequenceSuccess(res.data));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(fetchSequenceError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};
