import { combineReducers } from 'redux';
import {
    ACTIVE_INTERACTION_SET,
    BADGE_CLAIM_FETCH_ERROR,
    BADGE_CLAIM_FETCH_IN_PROGRESS,
    BADGE_CLAIM_FETCH_SUCCESS,
    CLEAR_DATA,
    DEVICE_ID_FETCH_ERROR,
    DEVICE_ID_FETCH_IN_PROGRESS,
    DEVICE_ID_FETCH_SUCCESS,
    INTERACTIVE_SAVE_SET,
    INTERACTIVE_VIDEO_ANSWER_SAVE_ERROR,
    INTERACTIVE_VIDEO_ANSWER_SAVE_IN_PROGRESS,
    INTERACTIVE_VIDEO_ANSWER_SAVE_SUCCESS,
    INTERACTIVE_VIDEO_FETCH_ERROR,
    INTERACTIVE_VIDEO_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEO_FETCH_SUCCESS,
    INTERACTIVE_VIDEO_SESSION_ADD_ERROR,
    INTERACTIVE_VIDEO_SESSION_ADD_IN_PROGRESS,
    INTERACTIVE_VIDEO_SESSION_ADD_SUCCESS,
    INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR,
    INTERACTIVE_VIDEO_SESSION_COMPLETE_IN_PROGRESS,
    INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS,
    INTERACTIVE_VIDEOS_FETCH_ERROR,
    INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    IV_PARTICIPATED_DETAILS_FETCH_ERROR,
    IV_PARTICIPATED_DETAILS_FETCH_IN_PROGRESS,
    IV_PARTICIPATED_DETAILS_FETCH_SUCCESS,
    PLAYER_SET,
    FETCH_ADVERTISEMENTS_SUCCESS,
    FETCH_ADVERTISEMENTS_IN_PROGRESS,
    FETCH_ADVERTISEMENTS_ERROR,
    IV_ALL_PARTICIPANTS_DETAILS_FETCH_ERROR,
    IV_ALL_PARTICIPANTS_DETAILS_FETCH_IN_PROGRESS,
    IV_ALL_PARTICIPANTS_DETAILS_FETCH_SUCCESS,
} from '../constants/interactiveVideo';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../config';

const player = (state = null, action) => {
    if (action.type === PLAYER_SET) {
        return action.value;
    }

    return state;
};

const list = (state = {
    inProgress: false,
    value: [],
    limit: DEFAULT_LIMIT,
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
            limit: action.limit,
        };
    case INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const interaction = (state = {
    active: 0,
}, action) => {
    switch (action.type) {
    case ACTIVE_INTERACTION_SET:
        return {
            ...state,
            active: action.value,
        };
    default:
        return state;
    }
};

const deviceID = (state = {
    id: '',
    inProgress: false,
}, action) => {
    switch (action.type) {
    case DEVICE_ID_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case DEVICE_ID_FETCH_SUCCESS:
        return {
            id: action.value,
            inProgress: false,
        };
    case DEVICE_ID_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const _ = (state = {
    value: {},
    interactionsList: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEO_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return {
            value: action.value,
            interactionsList: action.value && action.value.interactions,
            inProgress: false,
        };
    case INTERACTIVE_VIDEO_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const answers = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case INTERACTIVE_SAVE_SET:
        return {
            ...state,
            value: action.value,
        };
    case INTERACTIVE_VIDEO_ANSWER_SAVE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEO_ANSWER_SAVE_SUCCESS:
    case CLEAR_DATA:
        return {
            inProgress: false,
            value: {},
        };
    case INTERACTIVE_VIDEO_ANSWER_SAVE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const session = (state = {
    data: {},
    inProgress: false,
    errorMsg: '',
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEO_SESSION_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEO_SESSION_ADD_SUCCESS:
        return {
            data: action.value,
            inProgress: false,
        };
    case INTERACTIVE_VIDEO_SESSION_ADD_ERROR:
        return {
            ...state,
            inProgress: false,
            errorMsg: action.message,
        };
    case CLEAR_DATA:
        return {
            data: {},
            inProgress: false,
        };
    default:
        return state;
    }
};

const completed = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEO_SESSION_COMPLETE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEO_SESSION_COMPLETE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case INTERACTIVE_VIDEO_SESSION_COMPLETE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const claimBadge = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case BADGE_CLAIM_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case BADGE_CLAIM_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case BADGE_CLAIM_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const ivParticipatedList = (state = {
    value: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case IV_PARTICIPATED_DETAILS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case IV_PARTICIPATED_DETAILS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case IV_PARTICIPATED_DETAILS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const ivAllParticipantsList = (state = {
    value: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case IV_ALL_PARTICIPANTS_DETAILS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case IV_ALL_PARTICIPANTS_DETAILS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case IV_ALL_PARTICIPANTS_DETAILS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export const advertisements = (state = {
    result: [],
    inProgress: false,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
}, action) => {
    switch (action.type) {
    case FETCH_ADVERTISEMENTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: false,
        };
    case FETCH_ADVERTISEMENTS_SUCCESS:
        return {
            ...state,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
        };
    case FETCH_ADVERTISEMENTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    player,
    list,
    interaction,
    deviceID,
    _,
    answers,
    session,
    completed,
    claimBadge,

    ivParticipatedList,
    ivAllParticipantsList,
    advertisements,
});
