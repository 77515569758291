import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import { CONNECT_BC_ACCOUNT_ERROR, PROFILE_DETAILS_FETCH_ERROR } from '../constants/account';
import { INTERACTIVE_VIDEO_SESSION_ADD_ERROR } from '../constants/interactiveVideo';
import { LOGIN_OTP_ERROR, LOGIN_OTP_SUCCESS, LOGIN_USER_ERROR } from '../constants/navBar';
import { UPLOAD_PROFILE_IMAGE_ERROR, UPLOAD_USER_DETAILS_ERROR } from '../constants/profile';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case MESSAGE_SHOW:
    case PROFILE_DETAILS_FETCH_ERROR:
    case INTERACTIVE_VIDEO_SESSION_ADD_ERROR:
    case LOGIN_OTP_ERROR:
    case LOGIN_OTP_SUCCESS:
    case LOGIN_USER_ERROR:
    case UPLOAD_PROFILE_IMAGE_ERROR:
    case UPLOAD_USER_DETAILS_ERROR:
    case CONNECT_BC_ACCOUNT_ERROR:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
