import React, { Component } from 'react';
import './index.css';
import withRouter from '../../components/WithRouter';
import * as PropTypes from 'prop-types';
import { initializeWeb3AuthChain, setUpdateUser } from '../../actions/account/Web3Auth';
import { initializeChain, initializeLeap, setDisconnect } from '../../actions/account/wallet';
import { connect } from 'react-redux';
import ConnectDialog from '../NavBar/ConnectDialog';
import { fetchProfileDetails } from '../../actions/account';
import { fetchAllowances, fetchAuthzAccount, fetchBalance, fetchGrantInfo } from '../../actions/account/BCDetails';
import { Button, Tooltip } from '@mui/material';
import variables from '../../utils/variables';
import { setNavBarTab } from '../../actions/navBar';
import profileIcon from '../../assets/dummy/profile.png';
import { cosmjsMsgSign, setAddress, setWallet } from '../../actions/account/cosmjsWallet';
import LeftSection from './LeftSection';
import { fetchProfileImage, showVerifiedAccountDialog } from '../../actions/profile';
import CircularProgress from '../../components/CircularProgress';
import { config } from '../../config';
import { GenericAuthorization } from 'cosmjs-types/cosmos/authz/v1beta1/authz';
import moment from 'moment';
import { showMessage } from '../../actions/snackbar';
import { DirectSecp256k1HdWallet } from '@cosmjs/proto-signing';

class TopNavBar extends Component {
    constructor (props) {
        super(props);

        this.handleRedirect = this.handleRedirect.bind(this);
        this.handleDisconnect = this.handleDisconnect.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.handleAuthzTransaction = this.handleAuthzTransaction.bind(this);
        this.handleConnect = this.handleConnect.bind(this);
    }

    componentDidMount () {
        const active = this.props.router && this.props.router.location && this.props.router.location.pathname &&
            this.props.router.location.pathname.split('/');
        if (active && active.length && active[1]) {
            if (active[1] === 'board' || active[1] === 'leaderboard' || active[1] === 'user') {
                this.props.setNavBarTab('board');
            } else if (active[1] === 'cast') {
                this.props.setNavBarTab('cast');
            } else if (active[1] === 'profile') {
                this.props.setNavBarTab('profile');
            } else if (active[1] === 'interactiveVideo') {
                this.props.setNavBarTab('home');
            } else if (active[1] === 'walletCreation') {
                this.props.setNavBarTab('');
            } else if (active[1] === 'about') {
                this.props.setNavBarTab('about');
            } else {
                this.props.setNavBarTab('home');
            }
        } else {
            this.props.setNavBarTab('home');
        }

        if (localStorage.getItem('acToken_agni_sakshi')) {
            this.props.fetchProfileImage();
            this.props.fetchProfileDetails((result) => {
                if (result && result.bc_account_address) {
                    this.props.setAddress(result.bc_account_address);
                    const profileID = result && result._id;
                    const mnemonic = localStorage.getItem(`agni_sakshi_${profileID}`);
                    if (mnemonic) {
                        this.props.fetchGrantInfo(result.bc_account_address, (info) => {
                            if (info && info.length) {
                                return null;
                            }

                            this.props.fetchAuthzAccount((res) => {
                                if (res) {
                                    this.handleAuthzTransaction(mnemonic).then(() => {
                                    });
                                }
                            });
                        });
                    }
                } else if (result) {
                    this.props.router.navigate('/walletCreation');
                }
            });
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.auth !== this.props.auth) {
            this.props.fetchProfileImage();
            this.props.fetchProfileDetails((result) => {
                if (result && result.bc_account_address) {
                    this.props.setAddress(result.bc_account_address);
                }
            });
        }
    }

    handleDisconnect () {
        this.props.setDisconnect();
        localStorage.removeItem('acToken_agni_sakshi');
        localStorage.removeItem('rfToken_agni_sakshi');
        localStorage.removeItem('agni_sakshi_address');
        localStorage.removeItem('agni_sakshi_leap');
        localStorage.removeItem('agni_sakshi_web3_auth');
        localStorage.removeItem('fee_grant_renew');
    }

    handleRedirect () {
        this.props.router.navigate('/');
    }

    handleTab (value) {
        this.props.setNavBarTab(value);
        if (value === 'home') {
            this.props.router.navigate('/');
        } else {
            this.props.router.navigate('/' + value);
        }
    }

    handleLogin () {
        this.props.router.navigate('/login');
    }

    async handleAuthzTransaction (mnemonic) {
        mnemonic = mnemonic && atob(mnemonic);
        const wallet = await DirectSecp256k1HdWallet.fromMnemonic(mnemonic, { prefix: config.PREFIX });

        if (!wallet) {
            return;
        }

        this.props.fetchAllowances(this.props.address, (allowances) => {
            const granterInfo = {};
            if (allowances && allowances.length) {
                allowances.map((val) => {
                    if (val && val.allowance && val.allowance.spend_limit && val.allowance.spend_limit.length) {
                        const amount = val.allowance.spend_limit.find((val1) => (val1.denom === config.COIN_MINIMAL_DENOM) &&
                            val1.amount && (val1.amount > 0.1 * (10 ** config.COIN_DECIMALS)));
                        if (amount && amount.amount) {
                            granterInfo.granter = val.granter;
                            granterInfo.amount = amount.amount / 10 ** config.COIN_DECIMALS;
                            granterInfo.expiration = val.allowance.expiration;
                        }
                    } else if (val && val.allowance && val.allowance.allowed_messages &&
                        val.allowance.allowed_messages.length) {
                        if (val && val.allowance && val.allowance.allowance &&
                            val.allowance.allowance.spend_limit && val.allowance.allowance.spend_limit.length) {
                            const amount = val.allowance.allowance.spend_limit.find((val1) => (val1.denom === config.COIN_MINIMAL_DENOM) &&
                                val1.amount && (val1.amount > 0.1 * (10 ** config.COIN_DECIMALS)));
                            if (amount && amount.amount) {
                                granterInfo.granter = val.granter;
                                granterInfo.amount = amount.amount / 10 ** config.COIN_DECIMALS;
                                granterInfo.expiration = val && val.allowance && val.allowance.allowance &&
                                    val.allowance.allowance.expiration;
                            }
                        }
                    }

                    return null;
                });
            }

            if (granterInfo && granterInfo.amount && (granterInfo.amount > 0)) {
                this.handleConnect(granterInfo, wallet);
            }
        });
    }

    handleConnect (granterInfo, wallet) {
        const gasValue = '200000';
        const aYearFromNow = new Date();
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 55);

        const Tx = {
            msgs: [{
                typeUrl: '/cosmos.authz.v1beta1.MsgGrant',
                value: {
                    granter: this.props.address,
                    grantee: (this.props.authzAccount && this.props.authzAccount.agni_sakshi_authz_account) ||
                        'omniflix1wh9kplprnffenmte43w3mndv3fjv0sk6pwq5xx',
                    grant: {
                        authorization: {
                            typeUrl: '/cosmos.authz.v1beta1.GenericAuthorization',
                            value: GenericAuthorization.encode(
                                GenericAuthorization.fromPartial({
                                    msg: '/OmniFlix.onft.v1beta1.MsgMintONFT',
                                }),
                            ).finish(),
                        },
                        expiration: {
                            seconds: moment(aYearFromNow).diff(new Date(), 'seconds'),
                        },
                    },
                },
            }, {
                typeUrl: '/cosmos.authz.v1beta1.MsgGrant',
                value: {
                    granter: this.props.address,
                    grantee: (this.props.authzAccount && this.props.authzAccount.agni_sakshi_authz_account) ||
                        'omniflix1wh9kplprnffenmte43w3mndv3fjv0sk6pwq5xx',
                    grant: {
                        authorization: {
                            typeUrl: '/cosmos.authz.v1beta1.GenericAuthorization',
                            value: GenericAuthorization.encode(
                                GenericAuthorization.fromPartial({
                                    msg: '/OmniFlix.itc.v1.MsgClaim',
                                }),
                            ).finish(),
                        },
                        expiration: {
                            seconds: moment(aYearFromNow).diff(new Date(), 'seconds'),
                        },
                    },
                },
            }],
            fee: {
                amount: [{
                    amount: String(gasValue * config.GAS_PRICE_STEP_AVERAGE),
                    denom: config.COIN_MINIMAL_DENOM,
                }],
                gas: String(gasValue),
                granter: granterInfo.granter,
            },
            memo: '',
        };
        this.props.cosmjsMsgSign(Tx, this.props.address, wallet, (result) => {
            console.log('', result);
        });
    }

    render () {
        return (
            <div className="top_nav_bar">
                <div>
                    <LeftSection profileDetails={this.props.profileDetails}/>
                    <div className="right">
                        <Button
                            className={this.props.activeTab === 'home' ? 'active_tab tab_value' : 'tab_value'}
                            onClick={() => this.handleTab('home')}>
                            {variables[this.props.lang].home}
                        </Button>
                        <Button
                            className={this.props.activeTab === 'board' ? 'active_tab tab_value' : 'tab_value'}
                            onClick={() => this.handleTab('board')}>
                            {variables[this.props.lang].board}
                        </Button>
                        <Button
                            className={this.props.activeTab === 'cast' ? 'active_tab tab_value' : 'tab_value'}
                            onClick={() => this.handleTab('cast')}>
                            {variables[this.props.lang].cast}
                        </Button>
                        <Button
                            className={this.props.activeTab === 'about' ? 'active_tab tab_value' : 'tab_value'}
                            onClick={() => this.handleTab('about')}>
                            {variables[this.props.lang].about}
                        </Button>
                        <div
                            className={this.props.activeTab === 'profile' ? 'profile_active profile_info' : 'profile_info'}>
                            {this.props.address
                                ? <Tooltip title="User Name">
                                    <Button className="user_info" onClick={() => this.handleTab('profile')}>
                                        <div>
                                            <p>
                                                {this.props.profileDetails && this.props.profileDetails.mobile_number &&
                                                    this.props.profileDetails.mobile_number.substring(0, 5)}
                                                ******
                                                {this.props.profileDetails && this.props.profileDetails.mobile_number &&
                                                    this.props.profileDetails.mobile_number.slice(-2)}
                                            </p>
                                            <div className="user_address">
                                                <div className="hash_text" title={this.props.address}>
                                                    <p className="name">{this.props.address}</p>
                                                    {this.props.address.slice(this.props.address.length - 6, this.props.address.length)}
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.profileImageInProgress
                                            ? <CircularProgress/>
                                            : this.props.profileImage && Object.keys(this.props.profileImage).length
                                                ? <img alt="profile" src={this.props.profileImage}/>
                                                : <img alt="profile" src={profileIcon}/>}
                                    </Button>
                                </Tooltip>
                                : <Button className="join_club" onClick={() => this.handleLogin()}>
                                    {variables[this.props.lang].join_the_club}
                                </Button>}
                        </div>
                    </div>
                </div>
                <ConnectDialog/>
            </div>
        );
    }
}

TopNavBar.propTypes = {
    activeTab: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    authzAccount: PropTypes.object.isRequired,
    cosmjsMsgSign: PropTypes.func.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    fetchAuthzAccount: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchGrantInfo: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    fetchProfileImage: PropTypes.func.isRequired,
    initializeChain: PropTypes.func.isRequired,
    initializeLeap: PropTypes.func.isRequired,
    keplrInitializeChain: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    profileImage: PropTypes.any.isRequired,
    profileImageInProgress: PropTypes.bool.isRequired,
    setAddress: PropTypes.func.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    setNavBarTab: PropTypes.func.isRequired,
    setUpdateUser: PropTypes.func.isRequired,
    setWallet: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    showVerifiedAccountDialog: PropTypes.func.isRequired,
    auth: PropTypes.string,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    }),
    web3auth: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        activeTab: state.navBar.navTab.value,
        address: state.account.connection.address,
        lang: state.language,
        auth: state.account.token.value,
        web3auth: state.account.web3Auth.web3auth.value,
        profileDetails: state.account.profileDetails.value,
        profileImage: state.profile.profileImage.value,
        profileImageInProgress: state.profile.profileImage.inProgress,
        authzAccount: state.account.bc.authzAccount.value,
    };
};

const actionToProps = {
    cosmjsMsgSign,
    initializeChain: initializeWeb3AuthChain,
    keplrInitializeChain: initializeChain,
    initializeLeap,
    fetchAllowances,
    fetchBalance,
    fetchProfileDetails,
    fetchProfileImage,
    fetchGrantInfo,
    fetchAuthzAccount,
    setDisconnect,
    setNavBarTab,
    setUpdateUser,
    setAddress,
    setWallet,
    showMessage,
    showVerifiedAccountDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(TopNavBar));
