import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../config';
import { combineReducers } from 'redux';
import {
    FETCH_CAST_IN_PROGRESS,
    FETCH_CAST_SUCCESS,
    FETCH_CAST_ERROR,
    FETCH_CREW_IN_PROGRESS,
    FETCH_CREW_SUCCESS,
    FETCH_CREW_ERROR,
} from '../constants/cast';

const castList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
}, action) => {
    switch (action.type) {
    case FETCH_CAST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CAST_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
        };
    case FETCH_CAST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const crewList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
}, action) => {
    switch (action.type) {
    case FETCH_CREW_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CREW_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
        };
    case FETCH_CREW_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    castList,
    crewList,
});
