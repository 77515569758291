import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { hideSnackbar } from '../actions/snackbar';
import Snackbar from '../components/Snackbar';
import withRouter from '../components/WithRouter';
import { fetchAccessToken } from '../actions/account';

class SnackbarMessage extends Component {
    componentDidUpdate (pp, ps, ss) {
        if (pp.message !== this.props.message) {
            switch (this.props.message) {
            case 'Token is expired':
            case 'Error occurred while verifying the JWT token.':
            case 'User Id and token combination does not exist.':
                this.props.onClose();

                if (this.props.tokenInProgress) {
                    return;
                }

                return this.props.fetchAccessToken((error) => {
                    if (error) {
                        this.props.router.navigate('/');
                        localStorage.removeItem('acToken_agni_sakshi');
                        localStorage.removeItem('rfToken_agni_sakshi');
                        localStorage.removeItem('agni_sakshi_address');
                        localStorage.removeItem('agni_sakshi_leap');
                        localStorage.removeItem('agni_sakshi_web3_auth');
                        localStorage.removeItem('fee_grant_renew');
                    }
                });
            default:
                break;
            }
        }
    }

    render () {
        return (
            <Snackbar
                message={this.props.message}
                open={this.props.open}
                onClose={this.props.onClose}/>
        );
    }
}

SnackbarMessage.propTypes = {
    fetchAccessToken: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    tokenInProgress: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        open: state.snackbar.open,
        message: state.snackbar.message,
        tokenInProgress: state.account.token.inProgress,
    };
};

const actionsToProps = {
    fetchAccessToken,
    onClose: hideSnackbar,
};

export default withRouter(connect(stateToProps, actionsToProps)(SnackbarMessage));
